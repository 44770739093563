import { useEffect, useRef, useState } from "react";
import Flag from "react-world-flags";
import axios from "axios";
import "../../../ProfileComponents/index.css";
import { toast } from "react-toastify";
import { Fab } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  updateAgentProfile,
  updateUserProfile,
} from "../../../../services/AuthApi";
import { fetchAgent, UpdateAgentStatus } from "../../../../services/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AgentsProfileInfoForm = ({ userInfo, loading, setLoading, refresh }) => {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [agentContract, setAgentContract] = useState("");
  const [selectedAgentContractImage, setSelectedAgentContractImage] =
    useState(null);
  const [isAgent, setIsAgent] = useState(false);

  const [error, setError] = useState({
    email: "",
    firstname: "",
    lastname: "",
  });
  const [showUploadImage, setshowUploadImage] = useState({
    agent_contract: false,
  });
  const [idDocument, setIdDocument] = useState("");
  const [isTitleDropdownOpen, setIsTitleDropdownOpen] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const history = useHistory();

  const titleDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        titleDropdownRef.current &&
        !titleDropdownRef.current.contains(event.target)
      ) {
        setIsTitleDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTitleOptionClick = (option) => {
    setTitle(option);
    handleChangeBgUpdate();
    setIsTitleDropdownOpen(false);
  };

  const handleChangeBgUpdate = () => {
    setIsUpdated(true);
  };

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    console.log("here");
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        if (type === "contract") {
          setSelectedAgentContractImage(reader.result);
          setAgentContract(file);
          toast("Your image has been uploaded successfully.", {
            type: "success",
          });
          setShowToast(true);
        }
      };
      reader.readAsDataURL(file);
    } else {
      toast("Image is not uploaded.", {
        type: "error",
      });
    }
  };
  useEffect(() => {
    console.log(showUploadImage);
    console.log(showToast);
  }, [showUploadImage, showToast]);

  const handleDeleteFile = (e, value, name) => {
    e.preventDefault();
    setSelectedAgentContractImage("");
    setshowUploadImage((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setAgentContract(null);
  };

  useEffect(() => {
    if (userInfo) {
      setLoading(false);
      setTitle(userInfo.title);
      setFirstName(userInfo.first_name);
      setLastName(userInfo.last_name);
      setMobileNumber(userInfo.phone_number);
      setEmail(userInfo.email_address);
      setAgentContract(userInfo.agent_contract);
      setIsAgent(userInfo.is_agent);
    } else {
      setLoading(true);
    }
  }, [userInfo]);

  const hanleUpdateUserProfile = async (e) => {
    e.preventDefault();
    setshowUploadImage((prev) => ({ ...prev, agent_contract: false }));

    if (!email || !isValidEmail(email)) {
      setError((prevState) => ({
        ...prevState,
        ["email"]: "Please enter a valid email address.",
      }));
      toast(`Please enter a valid email address.`, {
        type: "error",
      });
      return;
    } else {
      setError((prevState) => ({
        ...prevState,
        ["email"]: "",
      }));
    }
    if (!firstName?.trim()) {
      setError((prevState) => ({
        ...prevState,
        ["firstname"]: "Please enter first name",
      }));
      toast(`Please enter first name.`, {
        type: "error",
      });
      return;
    } else {
      setError((prevState) => ({
        ...prevState,
        ["firstname"]: "",
      }));
    }
    if (!lastName.trim()) {
      setError((prevState) => ({
        ...prevState,
        ["lastname"]: "Please enter last name",
      }));
      toast(`Please enter last name.`, {
        type: "error",
      });
      return;
    } else {
      setError((prevState) => ({
        ...prevState,
        ["lastname"]: "",
      }));
    }
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("first_name", firstName || "");
      formData.append("last_name", lastName || "");
      formData.append("email_address", email || "");
      formData.append("title", title || "");
      formData.append("phone_number", mobileNumber || "");
      formData.append("agent_contract", agentContract || "");
      console.log(agentContract, "AGENT CONTRACT");

      await updateAgentProfile(id, formData);
      refresh();
      // setIsUpdated(true);
      toast(`Status Updated`, {
        type: "success",
      });
    } catch (error) {
      console.error("Failed to fetch products", error);
      toast(`Error updating status for product please reload the page`, {
        type: "error",
      });
      setLoading(false);
    } finally {
      setLoading(false);
      setError({
        email: "",
        firstname: "",
        lastname: "",
      });
    }
  };
  const handleShowSelection = (e, value, name) => {
    e.preventDefault();

    setshowUploadImage((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const handleAgentFetch = async () => {
    try {
      const response = await fetchAgent(id);
      setIsAgent(response?.data?.is_agent);
    } catch (error) {
      console.error(`Error updating status for product ${id}:`, error);
      toast(`${error}`, {
        type: "error",
      });
      history.push("/agents");
    }
  };
  const handleAgentsStatusChange = async () => {
    const is_agent = !isAgent;

    try {
      await UpdateAgentStatus(id, is_agent);

      toast(`Status Updated`, {
        type: "success",
      });
    } catch (error) {
      console.error(`Error updating status for product ${id}:`, error);
      toast(`Error updating agent status please reload the page`, {
        type: "error",
      });
      setLoading(false);
    }
    handleAgentFetch();
  };

  useEffect(() => {
    handleAgentFetch();
  }, []);

  if (loading) {
    return (
      <div className="container-fluid bg-opacity-50 bg-gray-100 fixed inset-0 flex items-center justify-center">
        <div className="bg-white p-5 rounded-lg shadow-lg">
          <svg
            className="animate-spin h-8 w-8 text-gray-500 mx-auto"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8zm2 0a6 6 0 1010.196 4.816l-1.52-1.52A4 4 0 118 12v-2H6v2z"
            ></path>
          </svg>
        </div>
      </div>
    );
  }
  return (
    <div className=" min-h-[500px] w-full max-w-[1440px] inset-0 px-5">
      <div className="max-w-[1440px] gap-[32px] flex items-center justify-start">
        <div className="text-dark mb-[24px] mt-[24px] w-[280px] flex">
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M32.625 18.75C32.625 13.9875 28.7625 10.125 24 10.125C19.2375 10.125 15.375 13.9875 15.375 18.75C15.375 23.5125 19.2375 27.375 24 27.375C28.7625 27.375 32.625 23.5125 32.625 18.75ZM17.625 18.75C17.625 15.225 20.475 12.375 24 12.375C27.525 12.375 30.375 15.225 30.375 18.75C30.375 22.275 27.525 25.125 24 25.125C20.475 25.125 17.625 22.275 17.625 18.75Z"
              fill="black"
            />
            <path
              d="M9.56239 40.575C9.74989 40.6875 9.93739 40.725 10.1249 40.725C10.4999 40.725 10.9124 40.5375 11.0999 40.1625C13.7249 35.5125 18.6749 32.625 23.9999 32.625C29.3249 32.625 34.2749 35.5125 36.9374 40.1625C37.2374 40.6875 37.9499 40.875 38.4749 40.575C38.9999 40.275 39.1874 39.5625 38.8874 39.0375C35.8499 33.7125 30.1499 30.375 23.9999 30.375C17.8499 30.375 12.1499 33.7125 9.11239 39.0375C8.81239 39.5625 8.99989 40.275 9.56239 40.575Z"
              fill="black"
            />
          </svg>
          <div className="ml-2">
            <h2 className="text-[18px] font-[500] leading-[28px] text-[#101828] flex items-center">
              Personal Infomation
            </h2>
            <p className="my-0.5  text-[#475467] text-[14px] leading-[20px]">
              Agents Details
            </p>
          </div>
        </div>
        <div className="w-[512px] flex items-center justify-end">
          <label className="flex cursor-pointer">
            <input
              type="checkbox"
              checked={isAgent}
              onChange={() => handleAgentsStatusChange()}
              className="sr-only peer"
            />
            <div className=" mt-0.5 relative w-[36px] h-[21px] rounded-full  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] bg-[#F2F4F7] after:absolute after:top-[2.5px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all dark:border-gray-600 peer-checked:bg-[#067647] peer-checked:hover:bg-[#8638e5]"></div>
            <span className="ml-3 text-[14px] font-medium text-black-900 dark:text-black-300">
              {isAgent ? "Enabled" : "Disabled"}
              <p className="sub-brand-text  text-[14px] font-[400]">
                Allowed to submit
              </p>
            </span>
          </label>
        </div>
      </div>
      <hr />
      <div className="">
        <form className="">
          <div className="flex gap-[32px] my-7">
            <div className="w-[280px]">
              <label
                htmlFor="email"
                className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
              >
                Names
              </label>
            </div>
            <div className="w-[512px] space-y-5">
              <div className="grid grid-cols-12 gap-[24px]">
                <div className="col-span-6 relative inline-block text-left">
                  <div className="col-span-6 relative inline-block text-left">
                    <div
                      className="col-span-6 relative inline-block text-left"
                      ref={titleDropdownRef}
                    >
                      <button
                        type="button"
                        className="bg-white input-focus text-left w-[245px] h-[44px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus px-[14px] py-[10px]"
                        onClick={() =>
                          setIsTitleDropdownOpen(!isTitleDropdownOpen)
                        }
                      >
                        <span className="flex items-center justify-between">
                          {title}
                          <svg
                            className={` ms-2.5 ${
                              isTitleDropdownOpen ? "transform rotate-180" : ""
                            }`}
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M5 7.5L10 12.5L15 7.5"
                              stroke="#667085"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </button>

                      {isTitleDropdownOpen && (
                        <div className="z-20 absolute w-[242px] bg-white divide-y divide-gray-100 rounded-lg shadow block">
                          <ul className="py-1 text-sm text-gray-700 box-shadow dark:text-black-200">
                            <li>
                              <div
                                className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                                onClick={() => handleTitleOptionClick("Mr")}
                              >
                                Mr
                              </div>
                            </li>
                            <li>
                              <div
                                className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                                onClick={() => handleTitleOptionClick("Ms")}
                              >
                                Ms
                              </div>
                            </li>
                            <li>
                              <div
                                className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                                onClick={() => handleTitleOptionClick("Mrs")}
                              >
                                Mrs
                              </div>
                            </li>
                            <li>
                              <div
                                className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                                onClick={() => handleTitleOptionClick("Dr")}
                              >
                                Dr
                              </div>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-span-6"></div>
              </div>

              <div className="grid grid-cols-12 gap-[24px]">
                <div className="col-span-6">
                  <input
                    onChange={handleChangeBgUpdate}
                    style={{ height: "44px" }}
                    value={firstName}
                    onInput={(e) => setFirstName(e.target.value)}
                    type="text"
                    id="firstName"
                    aria-describedby="helper-text-explanation"
                    className="bg-white input-focus text-left w-full h-[44px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus px-[14px] py-[10px]"
                    placeholder="First Name"
                  />
                  {error.firstname && (
                    <p className="text-red-500">{error.firstname}</p>
                  )}
                </div>
                <div className="col-span-6">
                  <input
                    onChange={handleChangeBgUpdate}
                    style={{ height: "44px" }}
                    value={lastName}
                    onInput={(e) => setLastName(e.target.value)}
                    type="text"
                    id="lastName"
                    aria-describedby="helper-text-explanation"
                    className="bg-white input-focus w-full h-[44px] px-[20px] py-[10px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus"
                    placeholder="Last Name"
                  />
                  {error.lastname && (
                    <p className="text-red-500">{error.lastname}</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-[32px] my-7">
            <div className="w-[280px]">
              <label
                for="image"
                className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
              >
                {" "}
                Agent Contract
              </label>
            </div>
            <div className="flex w-[512px]">
              <div className="" style={{ width: "6rem" }}>
                <img
                  className="w-[64px] h-[64px] rounded-full border-[0.75px] border-opacity-[0.08] border-[#000]"
                  src={
                    selectedAgentContractImage
                      ? selectedAgentContractImage
                      : agentContract
                      ? userInfo.agent_contract
                      : "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
                  }
                  alt="Jese image"
                />
              </div>

              {(!showUploadImage.agent_contract || showToast) && (
                <div className="container-fluid justify-end w-[425px] flex space-x-12 ml-[8rem]">
                  <button
                    onClick={(e) =>
                      handleDeleteFile(e, false, "agent_contract")
                    }
                    className="font-medium text-[#475467] text-[14px] font-[500] leading-[20px]  cursor-pointer disabled:text-slate-400"
                  >
                    Delete
                  </button>
                  <button
                    onClick={(e) =>
                      handleShowSelection(e, true, "agent_contract")
                    }
                    className="font-medium text-[#0439D7] text-[14px] font-[500] leading-[20px]  cursor-pointer disabled:text-slate-400"
                  >
                    Update
                  </button>
                </div>
              )}
              {showUploadImage.agent_contract && !showToast && (
                <div className="flex  flex-row items-center justify-center w-full px-[16px] py-[24px] h-32 border-2 border-[#0439D7] border-solid rounded-lg cursor-pointer bg-white">
                  <label
                    for="dropzone-profile"
                    style={{ width: "20rem" }}
                    className="flex flex-col items-center justify-center rounded-lg cursor-pointer bg-white"
                  >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <div className="w-[40px] h-[40px] p-[10px] items-center justify-center flex rounded-[8px] box-shadow input-focus bg-[#FFF] border border-[#EAECF0]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M6.6665 13.3333L9.99984 10M9.99984 10L13.3332 13.3333M9.99984 10V17.5M16.6665 13.9524C17.6844 13.1117 18.3332 11.8399 18.3332 10.4167C18.3332 7.88536 16.2811 5.83333 13.7498 5.83333C13.5677 5.83333 13.3974 5.73833 13.3049 5.58145C12.2182 3.73736 10.2119 2.5 7.9165 2.5C4.46472 2.5 1.6665 5.29822 1.6665 8.75C1.6665 10.4718 2.36271 12.0309 3.48896 13.1613"
                            stroke="#475467"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <p className="font-[500] text-[14px] leading-[20px] text-[#475467]">
                        <span className="font-[500] text-[14px] leading-[20px] text-[#0439D7]">
                          Click to upload
                        </span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        SVG, PNG, JPG or GIF (MAX. 800x400px)
                      </p>
                    </div>

                    <input
                      onChange={handleChangeBgUpdate}
                      onInput={(e) => handleFileChange(e, "contract")}
                      id="dropzone-profile"
                      type="file"
                      className="hidden box-shadow input-focus"
                      accept=".jpg,.jpeg,.png,.gif"
                    />
                  </label>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
      <hr />
      <div className="text-dark mb-[24px] mt-[24px] flex">
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24 7.125H31.5C32.1375 7.125 32.625 6.6375 32.625 6C32.625 5.3625 32.1375 4.875 31.5 4.875H24C23.3625 4.875 22.875 5.3625 22.875 6C22.875 6.6375 23.3625 7.125 24 7.125Z"
            fill="black"
          />
          <path
            d="M16.5 7.125H18.375C19.0125 7.125 19.5 6.6375 19.5 6C19.5 5.3625 19.0125 4.875 18.375 4.875H16.5C15.8625 4.875 15.375 5.3625 15.375 6C15.375 6.6375 15.8625 7.125 16.5 7.125Z"
            fill="black"
          />
          <path
            d="M12.75 47.625H35.25C37.95 47.625 40.125 45.45 40.125 42.75V5.25C40.125 2.55 37.95 0.375 35.25 0.375H28.125C28.05 0.375 28.0125 0.375 27.9375 0.375C27.8625 0.375 27.825 0.375 27.75 0.375H12.75C10.05 0.375 7.875 2.55 7.875 5.25V42.75C7.875 45.45 10.05 47.625 12.75 47.625ZM10.125 5.25C10.125 3.7875 11.2875 2.625 12.75 2.625H27.75H27.9375H28.125H35.25C36.7125 2.625 37.875 3.7875 37.875 5.25V42.75C37.875 44.2125 36.7125 45.375 35.25 45.375H12.75C11.2875 45.375 10.125 44.2125 10.125 42.75V5.25Z"
            fill="black"
          />
          <path
            d="M24 43.125C26.2875 43.125 28.125 41.2875 28.125 39C28.125 36.7125 26.2875 34.875 24 34.875C21.7125 34.875 19.875 36.7125 19.875 39C19.875 41.2875 21.7125 43.125 24 43.125ZM24 37.125C25.05 37.125 25.875 37.95 25.875 39C25.875 40.05 25.05 40.875 24 40.875C22.95 40.875 22.125 40.05 22.125 39C22.125 37.95 22.95 37.125 24 37.125Z"
            fill="black"
          />
        </svg>
        <div className="ml-2">
          <h2 className="text-[18px] font-[500] leading-[28px] text-[#101828] flex items-center">
            Contact Information
          </h2>
          <p className="my-0.5 text-[#475467] text-[14px] leading-[20px]">
            Customer Contact details
          </p>
        </div>
      </div>
      <hr />
      <div className="">
        <form className="">
          <div className="flex gap-[32px] my-7">
            <div className="w-[280px]">
              <label
                for="email"
                className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
              >
                Email Address
              </label>
            </div>
            <div className="w-[512px]  space-y-5">
              <input
                onChange={handleChangeBgUpdate}
                type="email"
                style={{ height: "44px" }}
                value={email}
                onInput={(e) => setEmail(e.target.value)}
                id="product"
                aria-describedby="helper-text-explanation"
                className="bg-white box-shadow input-focus w-full h-full px-[20px] py-[10px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none block input input-focus"
                placeholder="Please email address"
              />

              {error.email ? <p className="text-red-500">{error.email}</p> : ""}
            </div>
          </div>
          <div className="flex gap-[32px] my-7">
            <div className="w-[280px]">
              <label
                for="email"
                className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
              >
                Mobile / Whatsapp Number
              </label>
            </div>
            <div className="w-[512px]  space-y-5">
              <input
                onChange={handleChangeBgUpdate}
                type="text"
                style={{ height: "44px" }}
                value={mobileNumber === "null" ? "" : mobileNumber}
                onInput={(e) => setMobileNumber(e.target.value)}
                id="product"
                aria-describedby="helper-text-explanation"
                className="bg-white box-shadow input-focus w-full h-full px-[20px] py-[10px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none focus:ring-purple-600 focus:border-purple-600 block input"
                placeholder="Mobile Number"
              />
            </div>
          </div>
        </form>
      </div>
      <hr />
      <div className="container flex justify-end my-5 space-x-5">
        <button className="bg-gray-20 hover:bg-gray-100 text-dark-900 border border-black-100 py-2 px-5 rounded-lg">
          Cancel
        </button>

        <button
          onClick={(e) => {
            hanleUpdateUserProfile(e);
            setshowUploadImage((prev) => ({
              ...prev,
              agent_contract: false,
            }));
          }}
          className={
            isUpdated
              ? `
            bg-[#0439D7] hover:bg-blue-700 text-white border border-black-100 py-2 px-5 rounded-lg`
              : `bg-white text-[#0439D7] hover:bg-[#0439D7] hover:text-white  border border-[#0439D7] py-2 px-5 rounded-lg`
          }
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default AgentsProfileInfoForm;
