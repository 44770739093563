import SideBar from "../components/DashboardComponents/SideBar";
import { ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import CreateCustomerForm from "../components/CreateCustomerProfile/createCustomerForm";
import Header from "../components/DashboardComponents/Header";

const CreateCustomer = () => {
 
  const location = useLocation();
  const userType = location.pathname.split("/")[1];
 

  return (
    <div className="flex p-0 ">
      <div className="flex flex-col w-full" style={{ marginLeft: "5rem" }}>
      <Header title={`New Customer`}/>
      <CreateCustomerForm/>
      </div>
      <SideBar page={userType}/>
      <ToastContainer
        type="success"
        promise="true"
        position="top-right"
        autoClose="3000"
        theme="colored"
      />
    </div>
  );
};

export default CreateCustomer;
