import axios from "axios";
import { jwtDecode as jwt_decode } from "jwt-decode";
import baseUrl from "./config";

const API_URL = `${baseUrl}/api/accounts`;

/**
 * Checks if the JWT access token is expired.
 * @returns {boolean} True if the token is expired or not present, false otherwise.
 */

export const isTokenExpired = () => {
  const accessToken = localStorage.getItem("AccessToken");
  if (!accessToken) {
    return true;
  }

  try {
    const decodedToken = jwt_decode(accessToken);
    const currentTime = Math.floor(Date.now() / 1000);
    return decodedToken.exp < currentTime;
  } catch (error) {
    return true;
  }
};

/**
 * Sends a login request to the API.
 * @param {Object} credentials - The login credentials.
 * @param {string} credentials.email - The user's email.
 * @param {string} credentials.password - The user's password.
 * @returns {Promise} Axios promise representing the login request.
 */

export const login = (email, password) => {
  return axios.post(`${API_URL}/login/`, email, password);
};

/**
 * Sends a signup request to the API.
 * @param {Object} credentials - The signup credentials.
 * @param {string} credentials.email - The user's email.
 * @param {string} credentials.username - The user's username.
 * @param {string} credentials.password - The user's password.
 * @returns {Promise} Axios promise representing the signup request.
 */

export const signup = (email, password, username) => {
  return axios.post(`${API_URL}/signup/`, email, password, username);
};

/**
 * Fetches User data from the API with authorization.
 * @returns {Promise} Axios promise representing the fetch request.
 */

export const getUserInfo = () => {
  const accessToken = localStorage.getItem("AccessToken");

  return axios.get(`${API_URL}/user/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

/**
 * Fetches Customer data from the API with authorization.
 * @returns {Promise} Axios promise representing the fetch request.
 */

export const getCustomerInfo = (id) => {
  const accessToken = localStorage.getItem("AccessToken");

  return axios.get(`${API_URL}/get/customer/info/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

/**
 * Fetches Customer data from the API with authorization.
 * @returns {Promise} Axios promise representing the fetch request.
 */

export const getAgentInfo = (id) => {
  const accessToken = localStorage.getItem("AccessToken");

  return axios.get(`${API_URL}/get/agent/${id}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

/**
 * Update profile using the API with authorization.
 * @returns {Promise} Axios promise representing the fetch request.
 */

export const updateUserProfile = (id, body) => {
  const accessToken = localStorage.getItem("AccessToken");

  return axios.put(`${API_URL}/user_profile/${id}/update/`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

/**
 * Update agent profile using the API with authorization.
 * @returns {Promise} Axios promise representing the fetch request.
 */
export const updateAgentProfile = (id, body) => {
  const accessToken = localStorage.getItem("AccessToken");

  return axios.put(`${API_URL}/agent_profile/${id}/update/`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};


export const uploadSignature = (signature, token) => {
  const formData = new FormData();
  formData.append("signature", signature);
  formData.append("token", token);

  return axios.put(`${API_URL}/uploadsignature/upload/`, formData);
};

export const CreateCustomer = (body) => {
  const accessToken = localStorage.getItem("AccessToken");

  return axios.post(`${baseUrl}/api/addcustomer/`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
