import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { toast } from "react-toastify";
import baseUrl from "../../services/config";
const installmentsArray = [
  "6 months",
  "9 months",
  "12 months",
  "15 months",
  "18 months",
  "24 months",
];

const CreateProductModal = ({ userId, isModalOpen, setIsModalOpen }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [productCategory, setProductCategory] = useState("");
  const [product, setProduct] = useState("");
  const [installments, setInstallments] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isOpenDate, setIsOpenDate] = useState(false);

  const [productPrice, setProductPrice] = useState(0);
  const [getAllProductCategories, setGetAllProductCategories] = useState([]);
  const [getAllProduct, setGetAllProduct] = useState([]);
  const [getFilteredProduct, setFilterdProduct] = useState([]);
  const [getAllInstallments, setGetAllInstallments] = useState([]);

  const [isproductCategoryDropDownOpen, setIsproductCategoryDropDownOpen] =
    useState(false);
  const [isInstallmentsDropDownOpen, setIsInstallmentsDropDownOpen] =
    useState(false);
  const [isProductDropDownOpen, setIsProductDropDownOpen] = useState(false);

  const productCategoryDrpopDownRef = useRef(null);
  const productDrpopDownRef = useRef(null);
  const installmentsDrpopDownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        productCategoryDrpopDownRef.current &&
        !productCategoryDrpopDownRef.current.contains(event.target)
      ) {
        setIsproductCategoryDropDownOpen(false);
      }
      if (
        productDrpopDownRef.current &&
        !productDrpopDownRef.current.contains(event.target)
      ) {
        setIsProductDropDownOpen(false);
      }
      if (
        installmentsDrpopDownRef.current &&
        !installmentsDrpopDownRef.current.contains(event.target)
      ) {
        setIsInstallmentsDropDownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const getProductAndCategory = async () => {
    try {
      const resGetCategory = await axios.get(
        `https://coverzen.co.zw/wp-json/wp/v2/loan-category`
      );
      const resGetProducts = await axios.get(
        `https://coverzen.co.zw/wp-json/wp/v2/product-loan`
      );

      setGetAllProductCategories(resGetCategory.data);
      setGetAllProduct(resGetProducts.data);
    } catch (error) {}
  };

  useEffect(() => {
    setGetAllInstallments(installmentsArray);
    getProductAndCategory();
  }, []);

  const handleSubmit = async () => {
    if (productCategory === "" || product === "" || installments === "") {
      toast.error("Please select all fields");
      return;
    }
    const adjustedDate = new Date(
      selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000
    );

    const accessToken = localStorage.getItem("AccessToken");

    try {
      await axios.post(
        `${baseUrl}/api/customerproduct/`,
        {
          user: userId,
          product: product,
          product_category: productCategory,
          tenure: installments,
          product_price: productPrice,
          inception_date: adjustedDate.toISOString(),
          status: "pending",
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setProductCategory("");
      setProduct("");
      setInstallments("");
      setSelectedDate(new Date());
      closeModal();
      setIsModalOpen(!isModalOpen);
      toast.success("Product added successfully");
    } catch (error) {
      console.log("submitted error : ", error);
    }
  };

  const handleSetProduct = (id) => {
    const filteredProducts = getAllProduct.filter((item) => {
      return (
        item["loan-category"] &&
        Array.isArray(item["loan-category"]) &&
        item["loan-category"].includes(id)
      );
    });
    setProduct("");

    setFilterdProduct(filteredProducts);
  };

  const daysOfWeek = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const renderDays = () => {
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth();
    const totalDays = getDaysInMonth(year, month);
    const firstDay = getFirstDayOfMonth(year, month);
    const days = [];

    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} className="text-gray-300"></div>);
    }

    for (let i = 1; i <= totalDays; i++) {
      const date = new Date(year, month, i);
      const isSelected = selectedDate.toDateString() === date.toDateString();
      const isToday = new Date().toDateString() === date.toDateString();
      days.push(
        <div
          key={i}
          className={`h-[36px] w-[36px] p-[6px] text-center cursor-pointer rounded-full ${
            isSelected
              ? "bg-[#0439D7] text-white"
              : isToday
              ? "border border-[#0439D7] text-[#0439D7]"
              : "hover:bg-gray-200"
          }`}
          onClick={() => handleDateClick(date)}
        >
          {i}
        </div>
      );
    }

    return days;
  };

  return (
    <div className="flex justify-center items-center bg-gray-100">
      {/* Open Modal Button */}
      <button
        onClick={openModal}
        className="text-[14px] font-medium border rounded-[4px] border-[#0439D7] leading-[20px] bg-[#FFFFFF] px-[14px] py-[10px] transition-all duration-300 hover:bg-blue-100 text-[#0439D7] flex space-x-2"
      >
        Add New Product
      </button>

      {/* Modal */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50">
          <div
            className="bg-white rounded-[16px]  shadow-lg w-[891px] h-[430px] p-[32px] relative transform transition-all duration-500 ease-in-out"
            style={{
              animation: "slide-down 0.5s ease-in-out",
            }}
          >
            <div>
              <div className="text-dark pb-[20px] flex">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.0234 34.125V27.75C18.0234 27.1125 17.5359 26.625 16.8984 26.625C16.2609 26.625 15.7734 27.1125 15.7734 27.75V34.125H13.8984C13.2609 34.125 12.7734 34.6125 12.7734 35.25C12.7734 35.8875 13.2609 36.375 13.8984 36.375H24.3984C25.0359 36.375 25.5234 35.8875 25.5234 35.25C25.5234 34.6125 25.0359 34.125 24.3984 34.125H22.8984V27.75C22.8984 27.1125 22.4109 26.625 21.7734 26.625C21.1359 26.625 20.6484 27.1125 20.6484 27.75V34.125H18.0234Z"
                    fill="black"
                  />
                  <path
                    d="M46.1484 39.75H44.5359C44.6109 39.525 44.6484 39.2625 44.6484 39V9C44.6484 7.35 43.2984 6 41.6484 6H23.6484C23.0109 6 22.5234 6.4875 22.5234 7.125V14.25C22.5234 14.8875 23.0109 15.375 23.6484 15.375H29.6484C30.2859 15.375 30.7734 14.8875 30.7734 14.25V8.25H41.6484C42.0609 8.25 42.3984 8.5875 42.3984 9V39C42.3984 39.4125 42.0609 39.75 41.6484 39.75H11.6484C11.2359 39.75 10.8984 39.4125 10.8984 39V9C10.8984 8.5875 11.2359 8.25 11.6484 8.25H18.7734C19.4109 8.25 19.8984 7.7625 19.8984 7.125C19.8984 6.4875 19.4109 6 18.7734 6H11.6484C9.99844 6 8.64844 7.35 8.64844 9V39C8.64844 39.2625 8.68594 39.525 8.76094 39.75H7.14844C6.51094 39.75 6.02344 40.2375 6.02344 40.875C6.02344 41.5125 6.51094 42 7.14844 42H46.1484C46.7859 42 47.2734 41.5125 47.2734 40.875C47.2734 40.2375 46.7859 39.75 46.1484 39.75Z"
                    fill="black"
                  />
                  <path
                    d="M1.14825 42.0371C1.78241 42.0371 2.2965 41.523 2.2965 40.8889C2.2965 40.2547 1.78241 39.7406 1.14825 39.7406C0.514089 39.7406 0 40.2547 0 40.8889C0 41.523 0.514089 42.0371 1.14825 42.0371Z"
                    fill="black"
                  />
                </svg>

                <div className="ml-2">
                  <h2 className="text-[18px] font-[500] leading-[28px] text-[#101828] flex items-center">
                    Add Product
                  </h2>
                  <p className="my-0.5 text-[#475467] text-[14px] leading-[20px]">
                    Interested Product
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div className="mt-4">
              <div className="flex gap-[32px] my-[1.3rem]">
                <div className="w-[280px]">
                  <label
                    for="product_Category"
                    className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                  >
                    Product Category
                  </label>
                </div>
                <div className="relative w-[512px]">
                  <div className="relative" ref={productCategoryDrpopDownRef}>
                    <button
                      type="button"
                      className="bg-white text-left w-[512px] h-[44px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus px-[14px] py-[10px]"
                      onClick={() =>
                        setIsproductCategoryDropDownOpen(
                          !isproductCategoryDropDownOpen
                        )
                      }
                    >
                      <span className="flex items-center justify-between">
                        {productCategory || "Please select product category"}{" "}
                        <svg
                          className={`  ${
                            isproductCategoryDropDownOpen
                              ? "transform rotate-180"
                              : ""
                          }`}
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M5 7.5L10 12.5L15 7.5"
                            stroke="#667085"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </button>

                    {isproductCategoryDropDownOpen && (
                      <div className="z-20 w-[512px] absolute bg-white rounded-lg shadow mt-0">
                        <div className="z-20 absolute w-[512px] bg-white divide-y divide-gray-100 rounded-lg shadow block">
                          <ul className="py-1 text-sm text-gray-700 box-shadow dark:text-black-200 max-h-[300px] overflow-scroll">
                            {getAllProductCategories.map((item, index) => (
                              <li key={index}>
                                <div
                                  className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                                  onClick={() => {
                                    setProductCategory(item.name);
                                    handleSetProduct(item.id);
                                    setIsproductCategoryDropDownOpen(
                                      !isproductCategoryDropDownOpen
                                    );
                                  }}
                                >
                                  {item.name}
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex gap-[32px] my-[1.3rem]">
                <div className="w-[280px]">
                  <label
                    for="product"
                    className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                  >
                    Product
                  </label>
                </div>
                <div className="relative w-[512px]">
                  <div className="relative" ref={productDrpopDownRef}>
                    <button
                      type="button"
                      className="bg-white text-left w-[512px] h-[44px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus px-[14px] py-[10px]"
                      onClick={() =>
                        setIsProductDropDownOpen(!isProductDropDownOpen)
                      }
                    >
                      <span className="flex items-center justify-between">
                        {product === "" ? "Please select product" : product}{" "}
                        <svg
                          className={`  ${
                            isProductDropDownOpen ? "transform rotate-180" : ""
                          }`}
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M5 7.5L10 12.5L15 7.5"
                            stroke="#667085"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </button>

                    {isProductDropDownOpen && (
                      <div className="z-20 w-[512px] absolute bg-white rounded-lg shadow mt-0">
                        <div className="z-20 absolute w-[512px] bg-white divide-y divide-gray-100 rounded-lg shadow block">
                          <ul className="py-1 text-sm text-gray-700 box-shadow dark:text-black-200 overflow-scroll max-h-[300px]">
                            {getFilteredProduct.map((item, index) => (
                              <li key={index}>
                                <div
                                  className="block px-4 py-2 cursor-pointer hover:bg-gray-100 overflow-scroll"
                                  onClick={() => {
                                    setProduct(item.title.rendered);
                                    setProductPrice(item.acf.lpprice);
                                    setIsProductDropDownOpen(
                                      !isProductDropDownOpen
                                    );
                                  }}
                                >
                                  {item.title.rendered}
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex gap-[32px] my-[1.3rem]">
                <div className="w-[280px]">
                  <label
                    for="Installments"
                    className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                  >
                    Installments
                  </label>
                </div>
                <div className="relative w-[512px]">
                  <div className="relative" ref={installmentsDrpopDownRef}>
                    <button
                      type="button"
                      className="bg-white text-left w-[512px] h-[44px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus px-[14px] py-[10px]"
                      onClick={() =>
                        setIsInstallmentsDropDownOpen(
                          !isInstallmentsDropDownOpen
                        )
                      }
                    >
                      <span className="flex items-center justify-between">
                        {installments || "Please select product"}{" "}
                        <svg
                          className={`  ${
                            isInstallmentsDropDownOpen
                              ? "transform rotate-180"
                              : ""
                          }`}
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M5 7.5L10 12.5L15 7.5"
                            stroke="#667085"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </button>

                    {isInstallmentsDropDownOpen && (
                      <div className="z-20 w-[512px] absolute bg-white rounded-lg shadow mt-0">
                        <div className="z-20 absolute w-[512px] bg-white divide-y divide-gray-100 rounded-lg shadow block">
                          <ul className="py-1 text-sm text-gray-700 box-shadow dark:text-black-200 max-h-[250px] overflow-scroll">
                            {getAllInstallments.map((item, index) => (
                              <li key={index}>
                                <div
                                  className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                                  onClick={() => {
                                    setInstallments(item);
                                    setIsInstallmentsDropDownOpen(
                                      !isInstallmentsDropDownOpen
                                    );
                                  }}
                                >
                                  {item}
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex gap-[32px] my-[1.3rem]">
                <div className="w-[280px]">
                  <label
                    for="Installments"
                    className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                  >
                    Date
                  </label>
                </div>
                <div className="relative w-[512px]">
                  <div className="relative">
                    <button
                      className="border px-4 py-2 rounded flex items-center space-x-2 custom-btn-focus"
                      onClick={() => setIsOpenDate(!isOpenDate)}
                    >
                      <svg
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21 10H3M16 2V6M8 2V6M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <span>
                        {selectedDate.toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </span>
                    </button>
                    {isOpenDate && (
                      <div className="absolute bottom-12 left-[-290px] bg-white shadow-lg p-4 rounded w-72 mt-2 z-10">
                        <div>
                          <div className="flex justify-between items-center space-x-2 p-[10px]">
                            <button
                              onClick={() =>
                                setSelectedDate(
                                  new Date(
                                    selectedDate.setMonth(
                                      selectedDate.getMonth() - 1
                                    )
                                  )
                                )
                              }
                            >
                              <svg
                                className="transform rotate-[90deg]"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M5 7.5L10 12.5L15 7.5"
                                  stroke="#667085"
                                  stroke-width="1.66667"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                            <span className="font-semibold">
                              {monthNames[selectedDate.getMonth()]}{" "}
                              {selectedDate.getFullYear()}
                            </span>
                            <button
                              onClick={() =>
                                setSelectedDate(
                                  new Date(
                                    selectedDate.setMonth(
                                      selectedDate.getMonth() + 1
                                    )
                                  )
                                )
                              }
                            >
                              <svg
                                className="transform rotate-[270deg]"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M5 7.5L10 12.5L15 7.5"
                                  stroke="#667085"
                                  stroke-width="1.66667"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div className="flex justify-between items-center gap-4 mb-3">
                          <button className="w-3/4 border px-4 py-2 rounded flex items-center space-x-2">
                            <span>
                              {selectedDate.toLocaleDateString("en-US", {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                              })}
                            </span>
                          </button>
                          <button
                            className="border px-4 py-2 rounded flex items-center space-x-2"
                            onClick={() => setSelectedDate(new Date())}
                          >
                            Today
                          </button>
                        </div>
                        <div className="grid grid-cols-7 gap-1 text-center">
                          {daysOfWeek.map((day) => (
                            <div
                              key={day}
                              className="text-[14px] font-[300] text-gray-500"
                            >
                              {day}
                            </div>
                          ))}
                          {renderDays()}
                        </div>
                        <div className="flex justify-between gap-3 mt-4">
                          <button
                            className="w-2/4 px-4 py-2 hover:bg-gray-100 border rounded"
                            onClick={() => setIsOpenDate(false)}
                          >
                            Cancel
                          </button>
                          <button
                            className="w-2/4 px-4 py-2 bg-[#0439D7] text-white rounded"
                            onClick={() => setIsOpenDate(false)}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <button
              onClick={handleSubmit}
              className="text-[14px] absolute bottom-[20px] right-[32px] font-medium border rounded-[4px] border-[#0439D7] leading-[20px] bg-[#FFFFFF] px-[14px] py-[10px] transition-all duration-300 hover:bg-blue-100 text-[#0439D7] flex space-x-2"
            >
              Save
            </button>

            {/* Close Button */}
            <button
              className="absolute top-[32px] right-[32px] text-gray-500 hover:text-gray-800 focus:outline-none"
              onClick={closeModal}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                  fill="#1D1B20"
                />
              </svg>
            </button>
          </div>
        </div>
      )}

      {/* Custom Animation */}
      <style>
        {`
          @keyframes slide-down {
            from {
              transform: translateY(-100%);
              opacity: 0;
            }
            to {
              transform: translateY(0);
              opacity: 1;
            }
          }
        `}
      </style>
    </div>
  );
};

export default CreateProductModal;
