import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./pages/Login";
import StatsDashboard from "./pages/StatsDashboard";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import Chat from "./pages/Chat";
import CreateCustomer from "./pages/CreateCustomer";
 
function App() {
  return (
    <Router>
      <Switch>
        <Route path="/:userType(customer|agent)/profile/:id" component={Profile} />
        <Route path="/login" component={Login} />
        <Route path="/:userType(customers|agents)" component={Dashboard} />
        <Route path="/create/customer" component={CreateCustomer} />
        <Route path="/:userType(customer|agent)/chat/:id" component={Chat} />
        <Route path="/" component={StatsDashboard} />
      </Switch>
    </Router>
  );
}

export default App;
