import { useEffect, useRef, useState } from "react";
import Flag from "react-world-flags";
import axios from "axios";
import "../../index.css";
import { toast } from "react-toastify";
import { CreateCustomer } from "../../services/AuthApi";

const FileUploader = ({
  selectedImage,
  showUploadImage,
  showToast,
  typeStr,
  handleFileChange,
  handleDeleteFile,
  handleShowSelection,
}) => (
  <div className="w-[512px] space-y-5">
    <div className="grid grid-cols-12 gap-[24px]">
      <div className="col-span-2">
        <img
          className="w-[64px] h-[64px] rounded-full border-[0.75px] border-opacity-[0.08]"
          src={
            selectedImage
              ? selectedImage
              : "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
          }
          alt={`${typeStr}_image`}
        />
      </div>
      <div className="col-span-10">
        {(!showUploadImage[typeStr] || showToast[typeStr]) && (
          <div className="col-span-10 flex justify-end mt-6 space-x-12 ml-[8rem]">
            <button
              onClick={() => handleDeleteFile(true, typeStr)}
              className="font-medium text-[#475467] text-[14px] leading-[20px] cursor-pointer disabled:text-slate-400"
            >
              Delete
            </button>
            <button
              onClick={() => handleShowSelection(true, typeStr)}
              className="font-medium text-[#0439D7] text-[14px] leading-[20px] cursor-pointer disabled:text-slate-400"
            >
              Update
            </button>
          </div>
        )}
        {showUploadImage[typeStr] && !showToast[typeStr] && (
          <div className="flex flex-row items-center justify-center px-[16px] py-[24px] h-32 border-2 border-[#0439D7] border-solid rounded-lg cursor-pointer bg-white">
            <label
              htmlFor={`dropzone-${typeStr}`}
              style={{ width: "20rem" }}
              className="flex flex-col items-center justify-center rounded-lg cursor-pointer bg-white"
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <div className="w-[40px] h-[40px] p-[10px] items-center justify-center flex rounded-[8px] box-shadow input-focus bg-[#FFF] border border-[#EAECF0]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M6.6665 13.3333L9.99984 10M9.99984 10L13.3332 13.3333M9.99984 10V17.5M16.6665 13.9524C17.6844 13.1117 18.3332 11.8399 18.3332 10.4167C18.3332 7.88536 16.2811 5.83333 13.7498 5.83333C13.5677 5.83333 13.3974 5.73833 13.3049 5.58145C12.2182 3.73736 10.2119 2.5 7.9165 2.5C4.46472 2.5 1.6665 5.29822 1.6665 8.75C1.6665 10.4718 2.36271 12.0309 3.48896 13.1613"
                      stroke="#475467"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="font-[500] text-[14px] leading-[20px] text-[#475467]">
                  <span className="font-[500] text-[14px] leading-[20px] text-[#0439D7]">
                    Click to upload
                  </span>{" "}
                  or drag and drop
                </p>
                <p className="text-xs text-[#475467]">
                  SVG, PNG, JPG or GIF (MAX. 800x400px)
                </p>
              </div>
              <input
                onInput={(e) => handleFileChange(e, typeStr)}
                id={`dropzone-${typeStr}`}
                type="file"
                className="hidden box-shadow input-focus"
                accept=".jpg,.jpeg,.png,.gif"
              />
            </label>
          </div>
        )}
      </div>
    </div>
  </div>
);

const cities = [
  "Arcturus",
  "Banket",
  "Beatrice",
  "Beitbridge",
  "Bindura",
  "Binga",
  "Birchenough Bridge",
  "Buffalo Ranch",
  "Bulawayo",
  "Bvumba",
  "Chegutu",
  "Chewore",
  "Chilojo Cliffs",
  "Chimanimani",
  "Chinhoyi",
  "Chipinge",
  "Chiredzi",
  "Chirundu",
  "Chitungwiza",
  "Chivero",
  "Chivhu",
  "Chivi",
  "Dande",
  "Darwendale",
  "Dete",
  "Epworth",
  "Figtree",
  "Gokwe",
  "Gonarezhou",
  "Gwanda",
  "Gwayi",
  "Gweru",
  "Harare",
  "Honde Valley",
  "Hurungwe",
  "Hwange",
  "Juliasdale",
  "Kadoma",
  "Kanyemba",
  "Kariba",
  "Karoi",
  "Kwekwe",
  "Lalapansi",
  "Lupane",
  "Macheke",
  "Makuti",
  "Mana Pools",
  "Marondera",
  "Masvingo",
  "Matetsi",
  "Matopos",
  "Matusadonha",
  "Mazowe",
  "Mhangura",
  "Mhondoro",
  "Mudzi",
  "Murewa",
  "Mutare",
  "Mutoko",
  "Mvuma",
  "Mwenezi",
  "Ngezi",
  "Norton",
  "Nyanga",
  "Nyazura",
  "Nyika",
  "Penhalonga",
  "Plumtree",
  "Redcliff",
  "Rusape",
  "Rutenga",
  "Ruwa",
  "Save",
  "Save Valley",
  "Seke",
  "Selous",
  "Shamva",
  "Shangani",
  "Shurugwi",
  "Triangle",
  "Tsholotsho",
  "Victoria Falls",
  "Vumba",
  "Wedza",
  "West Nicholson",
  "Zambezi",
  "Zvishavane",
];
const installmentsArray = [
  "6 months",
  "9 months",
  "12 months",
  "15 months",
  "18 months",
  "24 months",
];
const names = ["Mr", "Ms", "Mrs", "Dr"];
const employerNames = [
  "Agriculture Research Centre",
  "Broadcasting Authority of Zimbabwe",
  "Consumer Council of Zimbabwe",
  "Doctor",
  "Ek'uphumuleni Geriatic Nursing Home",
  "Food and Nutrition Council",
  "King George VI Children's Rehabilitation Centre",
  "National Arts Council Of Zimbabwe",
  "National Economic Consultative Forum",
  "National Gallery Of Zimbabwe",
  "National Museums and Monuments of Zimbabwe",
  "New Ziana",
  "Nurse",
  "Research Council of Zimbabwe",
  "SIRDC",
  "Sports And Recreation Commission",
  "St Giles",
  "State Enterprises Restructuring Agency",
  "Teacher",
  "Technological and Commercial Information Promotion System",
  "TOSE",
  "ZARNET",
  "Zimbabwe Electoral Commission Service Providers",
  "Zimbabwe Film and Television School of Southern Africa",
  "Zimbabwe Media Commission",
  "Zimbabwe National Family Planning Council",
  "Zimbabwe Republic Police",
  "Zimbabwe Youth Council",
  "Zimcare Trust",
];

const CreateCustomerForm = () => {
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("Mr");
  const [gender, setGender] = useState("");
  const [kinGender, setKinGender] = useState("");
  const [kinDocumentType, setkinDocumentType] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [ID, setId] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [landlineNumber, setLandlineNumber] = useState("");
  const [country, setCountry] = useState("");
  const [error, setError] = useState({
    email: "",
    firstname: "",
    lastname: "",
    mobileNumber: "",
  });
  const [productPrice, setProductPrice] = useState(0);
  const [physicalAddress1, setPhysicalAddress1] = useState("");
  const [physicalAddress2, setPhysicalAddress2] = useState("");
  const [nextOfKinPhysicalAddress, setnexOfKinPhysicalAddress] = useState("");
  const [nextOfKinPhysicalAddress2, setnexOfKinPhysicalAddress2] = useState("");
  const [nextOfKinWhatsapp, setnexOfKinWhatsapp] = useState("");
  const [city, setCity] = useState("");
  const [employer, setEmployer] = useState("");
  const [ecNumber, setEcNumber] = useState("");
  const [occupation, setOccupation] = useState("");
  const [stationedAt, setStationedAt] = useState("");
  const [nextOfKinFirstName, setnextOfKinFirstName] = useState("");
  const [nextOfKinLastName, setnextOfKinLastName] = useState("");
  const [nextOfKinDateOfBirth, setnextOfKinDateOfBirth] = useState("");
  const [nextOfKinIDDocument, setnextOfKinIDDocument] = useState("");
  const [nextOfKinId, setnextOfKinId] = useState("");
  const [nextOfKinRelation, setnextOfKinRelation] = useState("");
  const [nextOfKinCountry, setnextOfKinCountry] = useState("");

  const [countries, setCountries] = useState([]);
  const [isTitleDropdownOpen, setIsTitleDropdownOpen] = useState(false);
  const [isTitleDropdownOpenKin, setIsTitleDropdownOpenKin] = useState(false);
  const [isCityDropdownOpen, setIsCityDropdownOpen] = useState(false);
  const [isCityDropdownOpenKin, setIsCityDropdownOpenKin] = useState(false);
  const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);

  const [isSexDropDownOpen, setIsSexDropDownOpen] = useState(false);
  const [isSexKinDropDown, setIsSexKinDropDown] = useState(false);
  const [isRelationDropDownOpen, setIsRelationDropDownOpen] = useState(false);

  const [isIdDocumentTypeOpen, setIdDocumentTypeOpen] = useState(false);
  const [isKinIdDocumentTypeOpen, setisKinIdDocumentTypeOpen] = useState(false);

  const [isCountryDropdownOpenKin, setIsCountryDropdownOpenKin] =
    useState(false);
  const [isEmployerDropdownOpen, setIsEmployerDropdownOpen] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const [nextOfKinCity, setnextOfKinCity] = useState("");

  const [productCategory, setProductCategory] = useState("");
  const [product, setProduct] = useState("");
  const [installments, setInstallments] = useState("");
  const [getFilteredProduct, setFilterdProduct] = useState([]);

  const [isproductCategoryDropDownOpen, setIsproductCategoryDropDownOpen] =
    useState(false);
  const [isInstallmentsDropDownOpen, setIsInstallmentsDropDownOpen] =
    useState(false);
  const [isProductDropDownOpen, setIsProductDropDownOpen] = useState(false);

  const [getAllProductCategories, setGetAllProductCategories] = useState([]);
  const [getAllProduct, setGetAllProduct] = useState([]);
  const [getAllInstallments, setGetAllInstallments] = useState([]);
  const [getAllNames, setGetAllNames] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isOpenDate, setIsOpenDate] = useState(false);

  const titleDropdownRef = useRef(null);
  const titleDropdownRefKin = useRef(null);
  const cityDropdownRef = useRef(null);
  const cityKinDropdownRef = useRef(null);
  const countryDropdownRef = useRef(null);
  const countryKinDropdownRef = useRef(null);
  const employerDropdownRef = useRef(null);
  const sexDrpopDownRef = useRef(null);
  const kinSexDropDownRef = useRef(null);
  const idDocumentTypeDropDownRef = useRef(null);
  const kinIdDocumentTypeDropDownRef = useRef(null);
  const kinRelationRef = useRef(null);
  const dobRef = useRef(null);
  const dobRef_b = useRef(null);
  const productCategoryDrpopDownRef = useRef(null);
  const productDrpopDownRef = useRef(null);
  const installmentsDrpopDownRef = useRef(null);
  const handleSetProduct = (id) => {
    const filteredProducts = getAllProduct.filter((item) => {
      return (
        item["loan-category"] &&
        Array.isArray(item["loan-category"]) &&
        item["loan-category"].includes(id)
      );
    });
    setProduct("");
    setFilterdProduct(filteredProducts);
  };

  useEffect(() => {
    let dobRefCurrent = dobRef.current;
    let dobRef_bCurrent = dobRef_b.current;
    const handleClickOutside = (event) => {
      if (
        titleDropdownRef.current &&
        !titleDropdownRef.current.contains(event.target)
      ) {
        setIsTitleDropdownOpen(false);
      }
      if (
        sexDrpopDownRef.current &&
        !sexDrpopDownRef.current.contains(event.target)
      ) {
        setIsSexDropDownOpen(false);
      }
      if (
        productCategoryDrpopDownRef.current &&
        !productCategoryDrpopDownRef.current.contains(event.target)
      ) {
        setIsproductCategoryDropDownOpen(false);
      }
      if (
        productDrpopDownRef.current &&
        !productDrpopDownRef.current.contains(event.target)
      ) {
        setIsProductDropDownOpen(false);
      }
      if (
        installmentsDrpopDownRef.current &&
        !installmentsDrpopDownRef.current.contains(event.target)
      ) {
        setIsInstallmentsDropDownOpen(false);
      }
      if (
        kinRelationRef.current &&
        !kinRelationRef.current.contains(event.target)
      ) {
        setIsRelationDropDownOpen(false);
      }
      if (
        idDocumentTypeDropDownRef.current &&
        !idDocumentTypeDropDownRef.current.contains(event.target)
      ) {
        setIdDocumentTypeOpen(false);
      }
      if (
        kinSexDropDownRef.current &&
        !kinSexDropDownRef.current.contains(event.target)
      ) {
        setIsSexKinDropDown(false);
      }
      if (
        kinIdDocumentTypeDropDownRef.current &&
        !kinIdDocumentTypeDropDownRef.current.contains(event.target)
      ) {
        setisKinIdDocumentTypeOpen(false);
      }
      if (
        titleDropdownRefKin.current &&
        !titleDropdownRefKin.current.contains(event.target)
      ) {
        setIsTitleDropdownOpenKin(false);
      }
      if (
        cityDropdownRef.current &&
        !cityDropdownRef.current.contains(event.target)
      ) {
        setIsCityDropdownOpen(false);
      }
      if (
        cityKinDropdownRef.current &&
        !cityKinDropdownRef.current.contains(event.target)
      ) {
        setIsCityDropdownOpenKin(false);
      }
      if (
        countryDropdownRef.current &&
        !countryDropdownRef.current.contains(event.target)
      ) {
        setIsCountryDropdownOpen(false);
      }
      if (
        countryKinDropdownRef.current &&
        !countryKinDropdownRef.current.contains(event.target)
      ) {
        setIsCountryDropdownOpenKin(false);
      }
      if (
        employerDropdownRef.current &&
        !employerDropdownRef.current.contains(event.target)
      ) {
        setIsEmployerDropdownOpen(false);
      }
    };

    const handleFocusDob = () => {
      dobRefCurrent.type = "date";
    };

    const handleFocusDobB = () => {
      dobRef_bCurrent.type = "date";
    };

    if (dobRefCurrent) {
      dobRefCurrent.addEventListener("focus", handleFocusDob);
    }
    if (dobRef_bCurrent) {
      dobRef_bCurrent.addEventListener("focus", handleFocusDobB);
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);

      if (dobRefCurrent) {
        dobRefCurrent.removeEventListener("focus", handleFocusDob);
      }
      if (dobRef_bCurrent) {
        dobRef_bCurrent.removeEventListener("focus", handleFocusDobB);
      }
    };
  }, []);

  const handleTitleOptionClick = (option) => {
    setTitle(option);
    handleChangeBgUpdate();
    setIsTitleDropdownOpen(false);
  };
  const handleTitleOptionClickKin = (option) => {
    setTitle(option);
    setIsTitleDropdownOpenKin(false);
    handleChangeBgUpdate();
  };

  const handleCityOptionClick = (option) => {
    setCity(option);
    setIsCityDropdownOpen(false);
    handleChangeBgUpdate();
  };
  const handleCityOptionClickKin = (option) => {
    setnextOfKinCity(option);
    setIsCityDropdownOpenKin(false);
    handleChangeBgUpdate();
  };

  const handleCountryOptionClick = (option) => {
    setCountry(option);
    handleChangeBgUpdate();
    setIsCountryDropdownOpen(false);
  };
  const handleCountryKinOptionClick = (option) => {
    setnextOfKinCountry(option);
    setIsCountryDropdownOpenKin(false);
    handleChangeBgUpdate();
  };

  const handleEmployerOptionClick = (option) => {
    setEmployer(option);
    setIsEmployerDropdownOpen(false);
    handleChangeBgUpdate();
  };

  const handleChangeBgUpdate = () => {
    setIsUpdated(true);
  };

  const [showUploadImage, setshowUploadImage] = useState({
    profile: false,
    document: false,
    signature: false,
  });
  const [showToast, setShowToast] = useState({
    profile: false,
    document: false,
    signature: false,
  });

  const [selectedImages, setSelectedImages] = useState({
    profile: null,
    document: null,
    signature: null,
  });

  const [files, setFiles] = useState({
    profile: null,
    document: null,
    signature: null,
  });

  const handleFileChange = (e, type) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImages((prev) => ({
          ...prev,
          [type]: reader.result,
        }));

        setFiles((prev) => ({
          ...prev,
          [type]: file,
        }));

        toast(`Your ${type} has been uploaded successfully.`, {
          type: "success",
        });
      };

      reader.readAsDataURL(file);

      setshowUploadImage((prevState) => ({
        ...prevState,
        [type]: false,
      }));
      setShowToast((prevState) => ({
        ...prevState,
        [type]: true,
      }));
    } else {
      toast("Image is not uploaded.", {
        type: "error",
      });
    }
  };

  const handleDeleteFile = (value, name) => {
    setshowUploadImage((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setShowToast((prevState) => ({
      ...prevState,
      [name]: false,
    }));

    setFiles((prev) => ({
      ...prev,
      [name]: null,
    }));
    setSelectedImages((prev) => ({
      ...prev,
      [name]: null,
    }));
  };

  const handleShowSelection = (value, name) => {
    setshowUploadImage((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setShowToast((prevState) => ({
      ...prevState,
      [name]: false,
    }));
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        const resGetCategory = await axios.get(
          `https://coverzen.co.zw/wp-json/wp/v2/loan-category`
        );
        const resGetProducts = await axios.get(
          `https://coverzen.co.zw/wp-json/wp/v2/product-loan`
        );

        setGetAllProductCategories(resGetCategory.data);
        setGetAllProduct(resGetProducts.data);
        const sortedCountries = response.data.sort((a, b) =>
          a.name.common.localeCompare(b.name.common)
        );
        setCountries(sortedCountries);
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };

    fetchCountries();
    setGetAllInstallments(installmentsArray);
    setGetAllNames(names);
  }, []);

  const hanleUpdateUserProfile = async (e) => {
    e.preventDefault();
    setshowUploadImage((prev) => ({ ...prev, signature: false }));

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    if (!mobileNumber?.trim()) {
      setError((prevState) => ({
        ...prevState,
        mobileNumber: "Please enter mobile number.",
      }));
      toast(`Please enter mobile number`, {
        type: "error",
      });
      return;
    } else {
      setError((prevState) => ({
        ...prevState,
        mobileNumber: "",
      }));
    }
    if (!firstName?.trim()) {
      setError((prevState) => ({
        ...prevState,
        firstname: "Please enter first name",
      }));
      toast(`Please enter first name.`, {
        type: "error",
      });
      return;
    } else {
      setError((prevState) => ({
        ...prevState,
        firstname: "",
      }));
    }
    if (!lastName.trim()) {
      setError((prevState) => ({
        ...prevState,
        lastname: "Please enter last name",
      }));
      toast(`Please enter last name.`, {
        type: "error",
      });
      return;
    } else {
      setError((prevState) => ({
        ...prevState,
        lastname: "",
      }));
    }
    const adjustedDate = new Date(
      selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000
    );

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("first_name", firstName || "");
      formData.append("last_name", lastName || "");
      formData.append("email_address", email || "");
      formData.append("profile_image", files.profile || "");
      formData.append("title", title || "");
      formData.append("sex", gender || "");
      if (dateOfBirth === "NaN-NaN-NaN") {
        formData.append("date_of_birth", formatDate(dateOfBirth));
      }
      formData.append("id_document_type", documentType || "");
      formData.append("id_number", ID || "");
      formData.append("phone_number", mobileNumber || "");
      formData.append("landline_number", landlineNumber || "");
      formData.append("country", country || "");
      formData.append("address_line_1", physicalAddress1 || "");
      formData.append("address_line_2", physicalAddress2 || "");
      formData.append("city", city || "");
      formData.append("employer_name", employer || "");
      formData.append("ec_number", ecNumber || "");
      formData.append("occupation", occupation || "");
      formData.append("stationed", stationedAt || "");
      formData.append("next_of_kin_first_name", nextOfKinFirstName || "");
      formData.append("next_of_kin_last_name", nextOfKinLastName || "");
      if (nextOfKinDateOfBirth === "NaN-NaN-NaN") {
        formData.append(
          "next_of_kin_date_of_birth",
          formatDate(nextOfKinDateOfBirth)
        );
      }
      formData.append("next_of_kin_id_document", nextOfKinIDDocument || "");
      formData.append("next_of_kin_id_number", nextOfKinId || "");
      formData.append("relationship_to_next_of_kin", nextOfKinRelation || "");
      formData.append("id_document", files.document || "");
      formData.append("signatures", files.signature || "");
      formData.append("next_of_kin_sex", kinGender || "");
      formData.append("next_of_kin_id_document_type", kinDocumentType || "");
      formData.append(
        "next_of_kin_physical_address",
        nextOfKinPhysicalAddress || ""
      );
      formData.append(
        "next_of_kin_physical_address2",
        nextOfKinPhysicalAddress2 || ""
      );
      formData.append("whatsapp_phone_number", nextOfKinWhatsapp || "");
      formData.append("next_of_kin_city", nextOfKinCity || "");
      formData.append("next_of_kin_country", nextOfKinCountry || "");
      formData.append("product", product || "");
      formData.append("product_category", productCategory || "");
      formData.append("tenure", installments || "");
      formData.append("inception_date", adjustedDate.toISOString() || "");
      formData.append("product_price", productPrice || 0);

      await CreateCustomer(formData);
      toast.success(`Customer Added successfully`);
    } catch (error) {
      console.error("Failed to fetch products", error);
      toast(`Error updating status for product please reload the page`, {
        type: "error",
      });
      setLoading(false);
    } finally {
      setLoading(false);
      setError({
        email: "",
        firstname: "",
        lastname: "",
      });
    }
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const daysOfWeek = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const renderDays = () => {
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth();
    const totalDays = getDaysInMonth(year, month);
    const firstDay = getFirstDayOfMonth(year, month);
    const days = [];

    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} className="text-gray-300"></div>);
    }

    for (let i = 1; i <= totalDays; i++) {
      const date = new Date(year, month, i);
      const isSelected = selectedDate.toDateString() === date.toDateString();
      const isToday = new Date().toDateString() === date.toDateString();
      days.push(
        <div
          key={i}
          className={`h-[36px] w-[36px] p-[6px] text-center cursor-pointer rounded-full ${
            isSelected
              ? "bg-[#0439D7] text-white"
              : isToday
              ? "border border-[#0439D7] text-[#0439D7]"
              : "hover:bg-gray-200"
          }`}
          onClick={() => handleDateClick(date)}
        >
          {i}
        </div>
      );
    }

    return days;
  };

  if (loading) {
    return (
      <div className="container-fluid bg-opacity-50 bg-gray-100 fixed inset-0 flex items-center justify-center">
        <div className="bg-white p-5 rounded-lg shadow-lg">
          <svg
            className="animate-spin h-8 w-8 text-gray-500 mx-auto"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8zm2 0a6 6 0 1010.196 4.816l-1.52-1.52A4 4 0 118 12v-2H6v2z"
            ></path>
          </svg>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex items-center justify-center">
      <div className=" min-h-[500px] w-full max-w-[1440px] inset-0 px-5">
        <hr />
        <div className="text-dark mb-[24px] mt-[24px] flex">
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M32.625 18.75C32.625 13.9875 28.7625 10.125 24 10.125C19.2375 10.125 15.375 13.9875 15.375 18.75C15.375 23.5125 19.2375 27.375 24 27.375C28.7625 27.375 32.625 23.5125 32.625 18.75ZM17.625 18.75C17.625 15.225 20.475 12.375 24 12.375C27.525 12.375 30.375 15.225 30.375 18.75C30.375 22.275 27.525 25.125 24 25.125C20.475 25.125 17.625 22.275 17.625 18.75Z"
              fill="black"
            />
            <path
              d="M9.56239 40.575C9.74989 40.6875 9.93739 40.725 10.1249 40.725C10.4999 40.725 10.9124 40.5375 11.0999 40.1625C13.7249 35.5125 18.6749 32.625 23.9999 32.625C29.3249 32.625 34.2749 35.5125 36.9374 40.1625C37.2374 40.6875 37.9499 40.875 38.4749 40.575C38.9999 40.275 39.1874 39.5625 38.8874 39.0375C35.8499 33.7125 30.1499 30.375 23.9999 30.375C17.8499 30.375 12.1499 33.7125 9.11239 39.0375C8.81239 39.5625 8.99989 40.275 9.56239 40.575Z"
              fill="black"
            />
          </svg>
          <div className="ml-2">
            <h2 className="text-[18px] font-[500] leading-[28px] text-[#101828] flex items-center">
              Personal Infomation
            </h2>
            <p className="my-0.5  text-[#475467] text-[14px] leading-[20px]">
              Customer details
            </p>
          </div>
        </div>
        <hr />
        <div className="">
          <form className="">
            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  htmlFor="email"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  Names
                </label>
              </div>
              <div className="w-[512px] space-y-5">
                <div className="grid grid-cols-12 gap-[24px]">
                  <div className="col-span-6 relative inline-block text-left">
                    <div className="col-span-6 relative inline-block text-left">
                      <div
                        className="col-span-6 relative inline-block text-left"
                        ref={titleDropdownRef}
                      >
                        <button
                          type="button"
                          className="bg-white input-focus text-left w-[245px] h-[44px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus px-[14px] py-[10px]"
                          onClick={() =>
                            setIsTitleDropdownOpen(!isTitleDropdownOpen)
                          }
                        >
                          <span className="flex items-center justify-between">
                            {title}
                            <svg
                              className={` ms-2.5 ${
                                isTitleDropdownOpen
                                  ? "transform rotate-180"
                                  : ""
                              }`}
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M5 7.5L10 12.5L15 7.5"
                                stroke="#667085"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                        </button>

                        {isTitleDropdownOpen && (
                          <div className="z-20 absolute w-[242px] bg-white divide-y divide-gray-100 rounded-lg shadow block">
                            <ul className="py-1 text-sm text-gray-700 box-shadow dark:text-black-200">
                              {getAllNames.map((item, index) => (
                                <li key={index}>
                                  <div
                                    className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                                    onClick={() => handleTitleOptionClick(item)}
                                  >
                                    {item}
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6"></div>
                </div>

                <div className="grid grid-cols-12 gap-[24px]">
                  <div className="col-span-6">
                    <input
                      onChange={handleChangeBgUpdate}
                      style={{ height: "44px" }}
                      value={firstName}
                      onInput={(e) => setFirstName(e.target.value)}
                      type="text"
                      id="firstName"
                      aria-describedby="helper-text-explanation"
                      className="bg-white input-focus text-left w-full h-[44px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus px-[14px] py-[10px]"
                      placeholder="What's your first name?"
                    />
                    {error.firstname && (
                      <p className="text-red-500">{error.firstname}</p>
                    )}
                  </div>
                  <div className="col-span-6">
                    <input
                      onChange={handleChangeBgUpdate}
                      style={{ height: "44px" }}
                      value={lastName}
                      onInput={(e) => setLastName(e.target.value)}
                      type="text"
                      id="lastName"
                      aria-describedby="helper-text-explanation"
                      className="bg-white input-focus w-full h-[44px] px-[20px] py-[10px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus"
                      placeholder="What's your last name?"
                    />
                    {error.lastname && (
                      <p className="text-red-500">{error.lastname}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  htmlFor="image"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  Profile Picture
                </label>
              </div>
              <FileUploader
                selectedImage={selectedImages.profile}
                showUploadImage={showUploadImage}
                showToast={showToast}
                typeStr="profile"
                handleFileChange={handleFileChange}
                handleDeleteFile={handleDeleteFile}
                handleShowSelection={handleShowSelection}
              />
            </div>

            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="sex"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  Sex
                </label>
              </div>
              <div className="relative w-[512px]">
                <div className="relative" ref={sexDrpopDownRef}>
                  <button
                    type="button"
                    className="bg-white text-left w-[512px] h-[44px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus px-[14px] py-[10px]"
                    onClick={() => setIsSexDropDownOpen(!isSexDropDownOpen)}
                  >
                    <span className="flex items-center justify-between">
                      {gender || "Please select sex"}{" "}
                      {/* Display selected country or placeholder */}
                      <svg
                        className={`  ${
                          isSexDropDownOpen ? "transform rotate-180" : ""
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M5 7.5L10 12.5L15 7.5"
                          stroke="#667085"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </button>

                  {isSexDropDownOpen && (
                    <div className="z-20 w-[512px] absolute bg-white rounded-lg shadow mt-0">
                      <div className="z-20 absolute w-[512px] bg-white divide-y divide-gray-100 rounded-lg shadow block">
                        <ul className="py-1 text-sm text-gray-700 box-shadow dark:text-black-200">
                          <li>
                            <div
                              className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                setGender("Male");
                                setIsSexDropDownOpen(!isSexDropDownOpen);
                                handleChangeBgUpdate();
                              }}
                            >
                              Male
                            </div>
                          </li>
                          <li>
                            <div
                              className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                setGender("Female");
                                setIsSexDropDownOpen(!isSexDropDownOpen);
                                handleChangeBgUpdate();
                              }}
                            >
                              Female
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  htmlFor="date_of_birth"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  Date of Birth Ref
                </label>
              </div>
              <div className="relative flex-grow">
                <input
                  onChange={handleChangeBgUpdate}
                  type="text"
                  style={{ height: "44px" }}
                  value={dateOfBirth}
                  onInput={(e) => setDateOfBirth(e.target.value)}
                  id="input-group-1"
                  className="bg-white box-shadow input-focus w-[512px] h-full px-[12px] py-[10px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none focus:ring-purple-600 focus:border-purple-600 block"
                  placeholder="DD/MM/YYYY"
                  ref={dobRef}
                />
              </div>
            </div>

            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="id_document"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  ID Document Type
                </label>
              </div>
              <div className="relative w-[512px]">
                <div className="relative" ref={idDocumentTypeDropDownRef}>
                  <button
                    type="button"
                    className="bg-white text-left w-[512px] h-[44px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus px-[14px] py-[10px]"
                    onClick={() => setIdDocumentTypeOpen(!isIdDocumentTypeOpen)}
                  >
                    <span className="flex items-center justify-between">
                      {documentType || "Please select an ID document type"}{" "}
                      {/* Display selected country or placeholder */}
                      <svg
                        className={`  ${
                          isIdDocumentTypeOpen ? "transform rotate-180" : ""
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M5 7.5L10 12.5L15 7.5"
                          stroke="#667085"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </button>

                  {isIdDocumentTypeOpen && (
                    <div className="z-20 w-[512px] absolute bg-white rounded-lg shadow mt-0">
                      <div className="z-20 absolute w-[512px] bg-white divide-y divide-gray-100 rounded-lg shadow block">
                        <ul className="py-1 text-sm text-gray-700 box-shadow dark:text-black-200">
                          <li>
                            <div
                              className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                setDocumentType("Passport");
                                setIdDocumentTypeOpen(!isIdDocumentTypeOpen);
                                handleChangeBgUpdate();
                              }}
                            >
                              Passport
                            </div>
                          </li>
                          <li>
                            <div
                              className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                setDocumentType("National ID");
                                setIdDocumentTypeOpen(!isIdDocumentTypeOpen);
                                handleChangeBgUpdate();
                              }}
                            >
                              National ID
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="id"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  ID #
                </label>
              </div>
              <div className="w-[512px] ">
                <input
                  onChange={handleChangeBgUpdate}
                  style={{ height: "44px" }}
                  value={ID}
                  onInput={(e) => setId(e.target.value)}
                  type="text"
                  id="input-group-1"
                  className="bg-white w-full box-shadow input-focus h-full px-[20px] py-[10px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none focus:ring-purple-600 focus:border-purple-600 block input"
                  placeholder="ID#"
                />
              </div>
            </div>
            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="image"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  {" "}
                  ID Document
                </label>
              </div>
              <FileUploader
                selectedImage={selectedImages.document}
                showUploadImage={showUploadImage}
                showToast={showToast}
                typeStr="document"
                handleFileChange={handleFileChange}
                handleDeleteFile={handleDeleteFile}
                handleShowSelection={handleShowSelection}
              />
            </div>
          </form>
        </div>
        <hr />
        <div className="text-dark mb-[24px] mt-[24px] flex">
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.0234 34.125V27.75C18.0234 27.1125 17.5359 26.625 16.8984 26.625C16.2609 26.625 15.7734 27.1125 15.7734 27.75V34.125H13.8984C13.2609 34.125 12.7734 34.6125 12.7734 35.25C12.7734 35.8875 13.2609 36.375 13.8984 36.375H24.3984C25.0359 36.375 25.5234 35.8875 25.5234 35.25C25.5234 34.6125 25.0359 34.125 24.3984 34.125H22.8984V27.75C22.8984 27.1125 22.4109 26.625 21.7734 26.625C21.1359 26.625 20.6484 27.1125 20.6484 27.75V34.125H18.0234Z"
              fill="black"
            />
            <path
              d="M46.1484 39.75H44.5359C44.6109 39.525 44.6484 39.2625 44.6484 39V9C44.6484 7.35 43.2984 6 41.6484 6H23.6484C23.0109 6 22.5234 6.4875 22.5234 7.125V14.25C22.5234 14.8875 23.0109 15.375 23.6484 15.375H29.6484C30.2859 15.375 30.7734 14.8875 30.7734 14.25V8.25H41.6484C42.0609 8.25 42.3984 8.5875 42.3984 9V39C42.3984 39.4125 42.0609 39.75 41.6484 39.75H11.6484C11.2359 39.75 10.8984 39.4125 10.8984 39V9C10.8984 8.5875 11.2359 8.25 11.6484 8.25H18.7734C19.4109 8.25 19.8984 7.7625 19.8984 7.125C19.8984 6.4875 19.4109 6 18.7734 6H11.6484C9.99844 6 8.64844 7.35 8.64844 9V39C8.64844 39.2625 8.68594 39.525 8.76094 39.75H7.14844C6.51094 39.75 6.02344 40.2375 6.02344 40.875C6.02344 41.5125 6.51094 42 7.14844 42H46.1484C46.7859 42 47.2734 41.5125 47.2734 40.875C47.2734 40.2375 46.7859 39.75 46.1484 39.75Z"
              fill="black"
            />
            <path
              d="M1.14825 42.0371C1.78241 42.0371 2.2965 41.523 2.2965 40.8889C2.2965 40.2547 1.78241 39.7406 1.14825 39.7406C0.514089 39.7406 0 40.2547 0 40.8889C0 41.523 0.514089 42.0371 1.14825 42.0371Z"
              fill="black"
            />
          </svg>

          <div className="ml-2">
            <h2 className="text-[18px] font-[500] leading-[28px] text-[#101828] flex items-center">
              Product
            </h2>
            <p className="my-0.5 text-[#475467] text-[14px] leading-[20px]">
              Customer address details
            </p>
          </div>
        </div>
        <hr />
        <div className="flex gap-[32px] my-7">
          <div className="w-[280px]">
            <label
              for="product_Category"
              className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
            >
              Product Category
            </label>
          </div>
          <div className="relative w-[512px]">
            <div className="relative" ref={productCategoryDrpopDownRef}>
              <button
                type="button"
                className="bg-white text-left w-[512px] h-[44px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus px-[14px] py-[10px]"
                onClick={() =>
                  setIsproductCategoryDropDownOpen(
                    !isproductCategoryDropDownOpen
                  )
                }
              >
                <span className="flex items-center justify-between">
                  {productCategory || "Please select product category"}{" "}
                  <svg
                    className={`  ${
                      isSexDropDownOpen ? "transform rotate-180" : ""
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M5 7.5L10 12.5L15 7.5"
                      stroke="#667085"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </button>

              {isproductCategoryDropDownOpen && (
                <div className="z-20 w-[512px] absolute bg-white rounded-lg shadow mt-0">
                  <div className="z-20 absolute w-[512px] bg-white divide-y divide-gray-100 rounded-lg shadow block">
                    <ul className="py-1 text-sm text-gray-700 box-shadow dark:text-black-200 max-h-[300px] overflow-scroll">
                      {getAllProductCategories.map((item, index) => (
                        <li key={index}>
                          <div
                            className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => {
                              setProductCategory(item.name);
                              handleSetProduct(item.id);
                              setIsproductCategoryDropDownOpen(
                                !isproductCategoryDropDownOpen
                              );
                              handleChangeBgUpdate();
                            }}
                          >
                            {item.name}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-[32px] my-7">
          <div className="w-[280px]">
            <label
              for="product"
              className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
            >
              Product
            </label>
          </div>
          <div className="relative w-[512px]">
            <div className="relative" ref={productDrpopDownRef}>
              <button
                type="button"
                className="bg-white text-left w-[512px] h-[44px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus px-[14px] py-[10px]"
                onClick={() => setIsProductDropDownOpen(!isProductDropDownOpen)}
              >
                <span className="flex items-center justify-between">
                  {product || "Please select product"}{" "}
                  <svg
                    className={`  ${
                      isSexDropDownOpen ? "transform rotate-180" : ""
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M5 7.5L10 12.5L15 7.5"
                      stroke="#667085"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </button>

              {isProductDropDownOpen && (
                <div className="z-20 w-[512px] absolute bg-white rounded-lg shadow mt-0">
                  <div className="z-20 absolute w-[512px] bg-white divide-y divide-gray-100 rounded-lg shadow block">
                    <ul className="py-1 text-sm text-gray-700 box-shadow dark:text-black-200 max-h-[300px] overflow-scroll">
                      {getFilteredProduct.map((item, index) => (
                        <li key={index}>
                          <div
                            className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => {
                              setProduct(item.title.rendered);
                              setProductPrice(item.acf.lpprice);
                              setIsProductDropDownOpen(!isProductDropDownOpen);
                              handleChangeBgUpdate();
                            }}
                          >
                            {item.title.rendered}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-[32px] my-7">
          <div className="w-[280px]">
            <label
              for="Installments"
              className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
            >
              Installments
            </label>
          </div>
          <div className="relative w-[512px]">
            <div className="relative" ref={installmentsDrpopDownRef}>
              <button
                type="button"
                className="bg-white text-left w-[512px] h-[44px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus px-[14px] py-[10px]"
                onClick={() =>
                  setIsInstallmentsDropDownOpen(!isInstallmentsDropDownOpen)
                }
              >
                <span className="flex items-center justify-between">
                  {installments || "Please select product"}{" "}
                  <svg
                    className={`  ${
                      isInstallmentsDropDownOpen ? "transform rotate-180" : ""
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M5 7.5L10 12.5L15 7.5"
                      stroke="#667085"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </button>

              {isInstallmentsDropDownOpen && (
                <div className="z-20 w-[512px] absolute bg-white rounded-lg shadow mt-0">
                  <div className="z-20 absolute w-[512px] bg-white divide-y divide-gray-100 rounded-lg shadow block">
                    <ul className="py-1 text-sm text-gray-700 box-shadow dark:text-black-200 max-h-[300px] overflow-scroll">
                      {getAllInstallments.map((item, index) => (
                        <li key={index}>
                          <div
                            className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => {
                              setInstallments(item);
                              setIsInstallmentsDropDownOpen(
                                !isInstallmentsDropDownOpen
                              );
                              handleChangeBgUpdate();
                            }}
                          >
                            {item}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-[32px]  my-7">
          <div className="w-[280px]">
            <label
              for="Installments"
              className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
            >
              Date
            </label>
          </div>
          <div className="relative w-[512px]">
            <div className="relative">
              <button
                className="border px-4 py-2 rounded flex items-center space-x-2 custom-btn-focus"
                onClick={() => setIsOpenDate(!isOpenDate)}
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 10H3M16 2V6M8 2V6M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span>
                  {selectedDate.toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                </span>
              </button>
              {isOpenDate && (
                <div className="absolute bottom-12 left-[-290px] bg-white shadow-lg p-4 rounded w-72 mt-2 z-10">
                  <div>
                    <div className="flex justify-between items-center space-x-2 p-[10px]">
                      <button
                        onClick={() =>
                          setSelectedDate(
                            new Date(
                              selectedDate.setMonth(selectedDate.getMonth() - 1)
                            )
                          )
                        }
                      >
                        <svg
                          className="transform rotate-[90deg]"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M5 7.5L10 12.5L15 7.5"
                            stroke="#667085"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                      <span className="font-semibold">
                        {monthNames[selectedDate.getMonth()]}{" "}
                        {selectedDate.getFullYear()}
                      </span>
                      <button
                        onClick={() =>
                          setSelectedDate(
                            new Date(
                              selectedDate.setMonth(selectedDate.getMonth() + 1)
                            )
                          )
                        }
                      >
                        <svg
                          className="transform rotate-[270deg]"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M5 7.5L10 12.5L15 7.5"
                            stroke="#667085"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="flex justify-between items-center gap-4 mb-3">
                    <button className="w-3/4 border px-4 py-2 rounded flex items-center space-x-2">
                      <span>
                        {selectedDate.toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </span>
                    </button>
                    <button
                      className="border px-4 py-2 rounded flex items-center space-x-2"
                      onClick={() => setSelectedDate(new Date())}
                    >
                      Today
                    </button>
                  </div>
                  <div className="grid grid-cols-7 gap-1 text-center">
                    {daysOfWeek.map((day) => (
                      <div
                        key={day}
                        className="text-[14px] font-[300] text-gray-500"
                      >
                        {day}
                      </div>
                    ))}
                    {renderDays()}
                  </div>
                  <div className="flex justify-between gap-3 mt-4">
                    <button
                      className="w-2/4 px-4 py-2 hover:bg-gray-100 border rounded"
                      onClick={() => setIsOpenDate(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="w-2/4 px-4 py-2 bg-[#0439D7] text-white rounded"
                      onClick={() => setIsOpenDate(false)}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <hr />
        <div className="text-dark mb-[24px] mt-[24px] flex">
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24 7.125H31.5C32.1375 7.125 32.625 6.6375 32.625 6C32.625 5.3625 32.1375 4.875 31.5 4.875H24C23.3625 4.875 22.875 5.3625 22.875 6C22.875 6.6375 23.3625 7.125 24 7.125Z"
              fill="black"
            />
            <path
              d="M16.5 7.125H18.375C19.0125 7.125 19.5 6.6375 19.5 6C19.5 5.3625 19.0125 4.875 18.375 4.875H16.5C15.8625 4.875 15.375 5.3625 15.375 6C15.375 6.6375 15.8625 7.125 16.5 7.125Z"
              fill="black"
            />
            <path
              d="M12.75 47.625H35.25C37.95 47.625 40.125 45.45 40.125 42.75V5.25C40.125 2.55 37.95 0.375 35.25 0.375H28.125C28.05 0.375 28.0125 0.375 27.9375 0.375C27.8625 0.375 27.825 0.375 27.75 0.375H12.75C10.05 0.375 7.875 2.55 7.875 5.25V42.75C7.875 45.45 10.05 47.625 12.75 47.625ZM10.125 5.25C10.125 3.7875 11.2875 2.625 12.75 2.625H27.75H27.9375H28.125H35.25C36.7125 2.625 37.875 3.7875 37.875 5.25V42.75C37.875 44.2125 36.7125 45.375 35.25 45.375H12.75C11.2875 45.375 10.125 44.2125 10.125 42.75V5.25Z"
              fill="black"
            />
            <path
              d="M24 43.125C26.2875 43.125 28.125 41.2875 28.125 39C28.125 36.7125 26.2875 34.875 24 34.875C21.7125 34.875 19.875 36.7125 19.875 39C19.875 41.2875 21.7125 43.125 24 43.125ZM24 37.125C25.05 37.125 25.875 37.95 25.875 39C25.875 40.05 25.05 40.875 24 40.875C22.95 40.875 22.125 40.05 22.125 39C22.125 37.95 22.95 37.125 24 37.125Z"
              fill="black"
            />
          </svg>
          <div className="ml-2">
            <h2 className="text-[18px] font-[500] leading-[28px] text-[#101828] flex items-center">
              Contact Information
            </h2>
            <p className="my-0.5 text-[#475467] text-[14px] leading-[20px]">
              Customer contact details
            </p>
          </div>
        </div>
        <hr />
        <div className="">
          <form className="">
            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="email"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  Email Address
                </label>
              </div>
              <div className="w-[512px]  space-y-5">
                <input
                  onChange={handleChangeBgUpdate}
                  type="email"
                  style={{ height: "44px" }}
                  value={email}
                  onInput={(e) => setEmail(e.target.value)}
                  id="product"
                  aria-describedby="helper-text-explanation"
                  className="bg-white box-shadow input-focus w-full h-full px-[20px] py-[10px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none block input input-focus"
                  placeholder="Please enter customers email address"
                />

                {error.email ? (
                  <p className="text-red-500">{error.email}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="email"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  Mobile Number
                </label>
              </div>
              <div className="w-[512px]  space-y-5">
                <input
                  onChange={handleChangeBgUpdate}
                  type="text"
                  style={{ height: "44px" }}
                  value={mobileNumber === "null" ? "" : mobileNumber}
                  onInput={(e) => setMobileNumber(e.target.value)}
                  id="product"
                  aria-describedby="helper-text-explanation"
                  className="bg-white box-shadow input-focus w-full h-full px-[20px] py-[10px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none focus:ring-purple-600 focus:border-purple-600 block input"
                  placeholder="Please enter customers phone number"
                />
                {error.mobileNumber && (
                  <p className="text-red-500">{error.mobileNumber}</p>
                )}
              </div>
            </div>
            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="email"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  Landline
                </label>
              </div>
              <div className="w-[512px]  space-y-5">
                <input
                  onChange={handleChangeBgUpdate}
                  type="text"
                  style={{ height: "44px" }}
                  value={landlineNumber === "null" ? "" : landlineNumber}
                  onInput={(e) => setLandlineNumber(e.target.value)}
                  id="product"
                  aria-describedby="helper-text-explanation"
                  className="bg-white box-shadow input-focus w-full h-full px-[20px] py-[10px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none focus:ring-purple-600 focus:border-purple-600 block input"
                  placeholder="Please enter customers landline number"
                />
              </div>
            </div>
          </form>
        </div>
        <hr />
        <div className="text-dark mb-[24px] mt-[24px] flex">
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_3703_26759)">
              <path
                d="M37.875 17.625C37.875 13.5 34.5 10.125 30.375 10.125C26.25 10.125 22.875 13.5 22.875 17.625C22.875 21.75 26.25 25.125 30.375 25.125C34.5 25.125 37.875 21.75 37.875 17.625ZM25.125 17.625C25.125 14.7375 27.4875 12.375 30.375 12.375C33.2625 12.375 35.625 14.7375 35.625 17.625C35.625 20.5125 33.2625 22.875 30.375 22.875C27.4875 22.875 25.125 20.5125 25.125 17.625Z"
                fill="black"
              />
              <path
                d="M30.3749 41.5126C31.6124 41.5126 32.8124 41.1001 33.7874 40.3501C39.2249 36.1876 43.5374 30.6376 46.2374 24.3376C49.0124 17.8501 47.5499 10.3876 42.5249 5.4001C35.8124 -1.3124 24.8999 -1.3124 18.1874 5.4001C16.2749 7.3126 14.8124 9.6751 13.9874 12.2251C13.7999 12.8251 14.0999 13.4626 14.6999 13.6501C15.2999 13.8376 15.9374 13.5376 16.1249 12.9376C16.8374 10.7251 18.1124 8.6626 19.7999 7.0126C25.6499 1.1626 35.1374 1.1626 40.9874 7.0126C45.3374 11.3626 46.6124 17.8501 44.2124 23.4751C41.6624 29.4376 37.6124 34.6501 32.4749 38.5876C31.8749 39.0376 31.1624 39.2626 30.4499 39.2626C29.8124 39.2626 29.3249 39.7501 29.3249 40.3876C29.3249 41.0251 29.7749 41.5126 30.3749 41.5126Z"
                fill="black"
              />
              <path
                d="M5.25 27H10.875C11.5125 27 12 26.5125 12 25.875C12 25.2375 11.5125 24.75 10.875 24.75H5.25C4.5375 24.75 3.9 24.4875 3.4125 24C2.8875 23.475 2.625 22.8375 2.625 22.125V11.625C2.625 10.9875 2.1375 10.5 1.5 10.5C0.8625 10.5 0.375 10.9875 0.375 11.625V22.125C0.375 23.4375 0.9 24.6375 1.8 25.575C2.7375 26.5125 3.9375 27 5.25 27Z"
                fill="black"
              />
              <path
                d="M46.5 45.375H42.75C42.1125 45.375 41.625 45.8625 41.625 46.5C41.625 47.1375 42.1125 47.625 42.75 47.625H46.5C47.1375 47.625 47.625 47.1375 47.625 46.5C47.625 45.8625 47.1375 45.375 46.5 45.375Z"
                fill="black"
              />
              <path
                d="M1.5 47.6251H35.25C35.8875 47.6251 36.375 47.1376 36.375 46.5001C36.375 45.8626 35.8875 45.3751 35.25 45.3751H15.7125C16.425 44.5501 16.9125 43.5001 17.1375 42.3751H21.75C23.4 42.3751 24.75 41.0251 24.75 39.3751V33.0376C24.75 30.5251 23.3625 28.2376 21.15 27.0751L17.2875 25.0501L2.4375 1.50007C2.1375 0.975068 1.425 0.825068 0.9 1.12507C0.375 1.46257 0.225 2.17507 0.5625 2.70007L15.5625 26.4751C15.675 26.6251 15.825 26.7751 15.975 26.8876L20.1 29.0626C21.6 29.8501 22.5 31.3876 22.5 33.0376V39.3751C22.5 39.7876 22.1625 40.1251 21.75 40.1251H17.1375C16.6125 37.1626 13.9875 34.8751 10.875 34.8751C7.35 34.8751 4.5 37.7251 4.5 41.2501C4.5 42.8251 5.0625 44.2501 6.0375 45.3751H1.5C0.8625 45.3751 0.375 45.8626 0.375 46.5001C0.375 47.1376 0.8625 47.6251 1.5 47.6251ZM10.875 37.1251C13.1625 37.1251 15 38.9626 15 41.2501C15 43.5376 13.1625 45.3751 10.875 45.3751C8.5875 45.3751 6.75 43.5376 6.75 41.2501C6.75 38.9626 8.5875 37.1251 10.875 37.1251Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_3703_26759">
                <rect width="48" height="48" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <div className="ml-2">
            <h2 className="text-[18px] font-[500] leading-[28px] text-[#101828] flex items-center">
              Physical Address
            </h2>

            <p className="my-0.5 text-[#475467] text-[14px] leading-[20px]">
              Customer address details
            </p>
          </div>
        </div>
        <hr />
        <div className="">
          <form className="">
            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="email"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  Physical Address 1
                </label>
              </div>
              <div className="w-[512px]  space-y-5">
                <input
                  onChange={handleChangeBgUpdate}
                  type="text"
                  style={{ height: "44px" }}
                  value={physicalAddress1 === "null" ? "" : physicalAddress1}
                  onInput={(e) => setPhysicalAddress1(e.target.value)}
                  id="product"
                  aria-describedby="helper-text-explanation"
                  className="bg-white box-shadow input-focus w-full h-full px-[20px] py-[10px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none focus:ring-purple-600 focus:border-purple-600 block input"
                  placeholder="Please enter customers street address"
                />
              </div>
            </div>
            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="email"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  Physical Address 2
                </label>
              </div>
              <div className="w-[512px]  space-y-5">
                <input
                  onChange={handleChangeBgUpdate}
                  type="text"
                  style={{ height: "44px" }}
                  value={physicalAddress2 === "null" ? "" : physicalAddress2}
                  onInput={(e) => setPhysicalAddress2(e.target.value)}
                  id="product"
                  aria-describedby="helper-text-explanation"
                  className="bg-white box-shadow input-focus w-full h-full px-[20px] py-[10px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none focus:ring-purple-600 focus:border-purple-600 block input"
                  placeholder="If they have a second address line, please enter it here"
                />
              </div>
            </div>
            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="email"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  City
                </label>
              </div>
              <div className="w-[512px]" ref={cityDropdownRef}>
                <button
                  type="button"
                  className="bg-white text-left w-full h-[44px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus px-[14px] py-[10px]"
                  onClick={() => setIsCityDropdownOpen(!isCityDropdownOpen)}
                >
                  <span className="flex items-center justify-between">
                    {city || "Please select customers city"}

                    <svg
                      className={`  ${
                        isCityDropdownOpen ? "transform rotate-180" : ""
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M5 7.5L10 12.5L15 7.5"
                        stroke="#667085"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </button>

                {isCityDropdownOpen && (
                  <div className="z-20 w-[512px] absolute bg-white rounded-lg shadow mt-0">
                    <ul className="py-1 text-sm box-shadow text-gray-700 dark:text-black-200  max-h-[300px] overflow-y-auto w-[512px] mt-0">
                      {cities.map((option, index) => (
                        <li key={index}>
                          <div
                            className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleCityOptionClick(option)}
                          >
                            {option}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  htmlFor="country"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  Country
                </label>
              </div>
              <div className="w-[512px] space-y-5">
                <div className="relative inline-block h-[44px]">
                  <div className="w-[512px]" ref={countryDropdownRef}>
                    <button
                      type="button"
                      className="bg-white text-left w-full h-[44px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus px-[14px] py-[10px]"
                      onClick={() =>
                        setIsCountryDropdownOpen(!isCountryDropdownOpen)
                      }
                    >
                      <span className="flex items-center justify-between">
                        {country || "Select a country"}
                        <svg
                          className={`transition-transform ${
                            isCountryDropdownOpen ? "transform rotate-180" : ""
                          }`}
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M5 7.5L10 12.5L15 7.5"
                            stroke="#667085"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </button>

                    {isCountryDropdownOpen && (
                      <div className="absolute z-30 w-[512px] bg-white rounded-lg shadow-lg mt-0">
                        <ul className="py-1 text-sm text-gray-700 max-h-[300px] overflow-y-auto">
                          {countries.map((country) => (
                            <li
                              key={country.cca3}
                              className="flex items-center space-x-2 px-3 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() =>
                                handleCountryOptionClick(country.name.common)
                              }
                            >
                              <Flag
                                code={country.cca3}
                                style={{ width: "32px", height: "32px" }}
                              />
                              <span>{country.name.common}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <hr />
        <div className="text-dark mb-[24px] mt-[24px] flex">
          <svg
            width="34"
            height="44"
            viewBox="0 0 34 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27 10C27 4.486 22.514 0 17 0C3.734 0.549 3.738 19.453 17 20C22.514 20 27 15.514 27 10ZM9 10C9 5.589 12.589 2 17 2C27.598 2.402 27.596 17.599 17 18C12.589 18 9 14.411 9 10Z"
              fill="black"
            />
            <path
              d="M1.76605 27.053L0.252046 40.669C0.159046 41.516 0.431046 42.366 1.00005 43.001C1.56905 43.636 2.38205 44 3.23405 44H30.765C32.501 44.038 33.977 42.391 33.746 40.669L32.233 27.053C32.045 25.361 31.024 23.895 29.5 23.132L23.446 20.106C23.165 19.965 22.833 19.965 22.551 20.106C19.074 21.844 14.922 21.844 11.446 20.106C11.165 19.965 10.833 19.965 10.551 20.106L4.49705 23.132C2.97605 23.895 1.95405 25.36 1.76605 27.053ZM14.237 42L16.814 29.973C16.937 30.007 17.061 30.006 17.185 29.973L19.763 42H14.237ZM15.303 26H18.698C18.989 25.991 19.113 26.401 18.866 26.555L17 27.798L15.134 26.555C14.889 26.4 15.01 25.991 15.303 26ZM5.39405 24.921L11.011 22.113C14.798 23.84 19.204 23.841 22.991 22.113L28.607 24.921C29.521 25.378 30.134 26.258 30.247 27.274L31.76 40.89C31.838 41.464 31.347 42.015 30.767 42H21.81L18.997 28.871L19.976 28.218C21.857 27.018 20.936 23.958 18.7 23.999H15.306C14.033 24 13 25.033 13 26.303C12.946 27.577 14.048 28.286 15.005 28.872L12.192 42.001H3.23505C2.73705 42.001 2.18605 41.566 2.24105 40.891L3.75405 27.275C3.86705 26.258 4.47905 25.378 5.39405 24.921Z"
              fill="black"
            />
            <path
              d="M8.00004 31H12C13.307 30.995 13.309 29.005 12 29H8.00004C6.69304 29.005 6.69104 30.995 8.00004 31Z"
              fill="black"
            />
          </svg>

          <div className="ml-2">
            <h2 className="text-[18px] font-[500] leading-[28px] text-[#101828] flex items-center">
              Employment Details
            </h2>
            <p className="my-0.5 text-[#475467] text-[14px] leading-[20px]">
              Customer employement details
            </p>
          </div>
        </div>
        <hr />
        <div className="">
          <form className="">
            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="email"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  Employer (Ministry/Municipality)
                </label>
              </div>
              <div className="w-[512px]">
                <div className="relative inline-block  h-[44px]">
                  <div className="w-[512px]" ref={employerDropdownRef}>
                    <button
                      type="button"
                      className="bg-white text-left w-full h-[44px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus px-[14px] py-[10px]"
                      onClick={() =>
                        setIsEmployerDropdownOpen(!isEmployerDropdownOpen)
                      }
                    >
                      <span className="flex items-center justify-between">
                        {employer || "Please select the customers employer"}
                        <svg
                          className={`  ${
                            isEmployerDropdownOpen ? "transform rotate-180" : ""
                          }`}
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M5 7.5L10 12.5L15 7.5"
                            stroke="#667085"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </button>

                    {isEmployerDropdownOpen && (
                      <div className="z-20 w-[512px] absolute bg-white rounded-lg shadow mt-0">
                        <ul className="py-1 text-sm text-gray-700 box-shadow dark:text-black-200  max-h-[300px] overflow-y-auto w-[512px] mt-0">
                          {employerNames.map((option, index) => (
                            <li key={index}>
                              <div
                                className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                                onClick={() =>
                                  handleEmployerOptionClick(option)
                                }
                              >
                                {option}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="text"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  EC Number
                </label>
              </div>
              <div className="w-[512px]  space-y-5">
                <input
                  onChange={handleChangeBgUpdate}
                  type="text"
                  style={{ height: "44px" }}
                  value={ecNumber}
                  onInput={(e) => setEcNumber(e.target.value)}
                  id="product"
                  aria-describedby="helper-text-explanation"
                  className="bg-white box-shadow input-focus w-full h-full px-[20px] py-[10px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none focus:ring-purple-600 focus:border-purple-600 block input"
                  placeholder="Please enter customers EC number"
                />
              </div>
            </div>
            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="text"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  Occupation
                </label>
              </div>
              <div className="w-[512px]  space-y-5">
                <input
                  onChange={handleChangeBgUpdate}
                  type="text"
                  style={{ height: "44px" }}
                  value={occupation === "null" ? "" : occupation}
                  onInput={(e) => setOccupation(e.target.value)}
                  id="product"
                  aria-describedby="helper-text-explanation"
                  className="bg-white box-shadow input-focus w-full h-full px-[20px] py-[10px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none focus:ring-purple-600 focus:border-purple-600 block input"
                  placeholder="Please enter customers occupation"
                />
              </div>
            </div>
            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="text"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  Stationed At
                </label>
              </div>
              <div className="w-[512px]  space-y-5">
                <input
                  onChange={handleChangeBgUpdate}
                  type="text"
                  style={{ height: "44px" }}
                  value={stationedAt === "null" ? "" : stationedAt}
                  onInput={(e) => setStationedAt(e.target.value)}
                  id="product"
                  aria-describedby="helper-text-explanation"
                  className="bg-white box-shadow input-focus w-full h-full px-[20px] py-[10px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none focus:ring-purple-600 focus:border-purple-600 block input"
                  placeholder="Please select where they are stationed"
                />
              </div>
            </div>
          </form>
        </div>

        <hr />
        <div className="text-dark mb-[24px] mt-[24px] flex">
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.8125 44.1C16.9125 44.925 15.7125 45.375 14.5125 45.375C13.875 45.375 13.3875 45.8625 13.3875 46.5C13.3875 47.1375 13.875 47.625 14.5125 47.625C16.275 47.625 18 46.9875 19.3125 45.7875C19.7625 45.375 19.8 44.6625 19.3875 44.2125C19.0125 43.725 18.3 43.6875 17.8125 44.1Z"
              fill="black"
            />
            <path
              d="M38.8875 28.0124C38.8875 27.9749 38.8875 27.9749 38.8875 28.0124L40.125 27.9749C42.45 27.9749 44.4375 26.2124 44.5875 23.9624C44.5875 23.9624 44.5875 23.7749 44.5875 23.5499C44.5875 22.9499 44.0625 22.4624 43.4625 22.4624C42.825 22.4624 42.3375 22.9874 42.3375 23.6249V23.8499C42.2625 24.9374 41.2875 25.7999 40.125 25.7999H37.9875C37.4625 25.7999 36.975 26.1749 36.8625 26.6999C35.1375 34.6874 30.3375 39.4499 23.9625 39.4499C17.625 39.4499 12.7875 34.6874 11.0625 26.6999C10.95 26.1749 10.4625 25.7999 9.9375 25.7999H7.875C6.6375 25.7999 5.625 24.8624 5.625 23.6624C5.625 23.0624 5.1375 22.5749 4.5375 22.5749H4.5C3.8625 22.4999 3.375 22.9874 3.375 23.5874C3.375 25.9874 5.4 27.9374 7.875 27.9374H9.1125C9.1125 27.9374 9.15 27.9374 9.15 27.9749C11.25 36.5624 16.7625 41.6249 24 41.6249C25.05 41.6249 26.0625 41.5124 27.0375 41.3249C27.0375 41.3249 27.075 41.3249 27.075 41.3624C27.525 44.8874 30.525 47.6249 34.1625 47.6624C34.725 47.6624 35.25 47.2874 35.325 46.7249C35.4375 46.0124 34.9125 45.4124 34.2 45.4124C31.5375 45.4124 29.3625 43.3124 29.2125 40.6874V40.6499C33.9 38.7374 37.3125 34.3124 38.8875 28.0124Z"
              fill="black"
            />
            <path
              d="M7.65003 19.275C8.21253 19.575 8.77503 19.725 9.37503 19.725C10.425 19.725 11.3625 19.275 12.0375 18.4125C12.3375 18.0375 12.5625 17.6625 12.7125 17.2875C13.425 15.45 14.475 12.6375 15.7125 12.2625C16.1625 12.1125 16.725 12.3375 17.4375 12.825C17.925 13.2 29.5875 21.6375 33.4125 21.9H33.4875H40.875C41.175 21.9 41.475 21.7875 41.6625 21.5625C41.8875 21.3375 42 21.075 42 20.775C41.9625 15.675 40.125 10.575 36.9375 6.75C33.525 2.625 28.95 0.375 24 0.375C15.975 0.375 8.55003 6.4125 5.92503 15.0375L5.88753 15.1125C5.40003 16.7625 6.07503 18.45 7.65003 19.275ZM8.02503 15.75L8.06253 15.675C10.3875 7.9875 16.95 2.625 24 2.625C28.2375 2.625 32.25 4.6125 35.2125 8.2125C37.8375 11.3625 39.45 15.4875 39.7125 19.6875H33.5625C31.5 19.4625 24.45 15.1125 18.7875 11.025C17.8125 10.3125 16.9125 9.975 16.05 9.975C15.7125 9.975 15.4125 10.0125 15.1125 10.125C12.975 10.7625 11.8125 13.3875 10.65 16.5C10.575 16.65 10.5 16.8375 10.35 16.9875C10.1625 17.2125 9.86253 17.475 9.41253 17.475C9.18753 17.475 8.96253 17.4 8.73753 17.2875C8.10003 16.95 7.83753 16.3875 8.02503 15.75Z"
              fill="black"
            />
          </svg>

          <div className="ml-2">
            <h2 className="text-[18px] font-[500] leading-[28px] text-[#101828] flex items-center">
              Next of Kin
            </h2>
            <p className="my-0.5 text-[#475467] text-[14px] leading-[20px]">
              Next of kin details
            </p>
          </div>
        </div>
        <hr />
        <div className="">
          <form className="">
            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="email"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  Name
                </label>
              </div>
              <div className="w-[512px]">
                <div
                  className="relative inline-block w-[242px] h-[44px]"
                  ref={titleDropdownRefKin}
                >
                  <button
                    type="button"
                    className="bg-white text-left w-[240px] h-[44px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus px-[14px] py-[10px]"
                    onClick={() =>
                      setIsTitleDropdownOpenKin(!isTitleDropdownOpenKin)
                    }
                  >
                    <span className="flex items-center justify-between">
                      {title}
                      <svg
                        className={`  ${
                          isTitleDropdownOpenKin ? "transform rotate-180" : ""
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M5 7.5L10 12.5L15 7.5"
                          stroke="#667085"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </button>

                  {isTitleDropdownOpenKin && (
                    <div className="z-20 absolute w-[242px] bg-white divide-y divide-gray-100 rounded-lg shadow">
                      <ul className="py-1 text-sm text-gray-700 box-shadow dark:text-black-200">
                        <li>
                          <div
                            className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleTitleOptionClickKin("Mr")}
                          >
                            Mr
                          </div>
                        </li>
                        <li>
                          <div
                            className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleTitleOptionClickKin("Ms")}
                          >
                            Ms
                          </div>
                        </li>
                        <li>
                          <div
                            className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleTitleOptionClickKin("Mrs")}
                          >
                            Mrs
                          </div>
                        </li>
                        <li>
                          <div
                            className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleTitleOptionClickKin("Dr")}
                          >
                            Dr
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>

                <div className="flex mt-7 gap-[32px]">
                  <input
                    onChange={handleChangeBgUpdate}
                    style={{ height: "44px" }}
                    value={nextOfKinFirstName}
                    onInput={(e) => setnextOfKinFirstName(e.target.value)}
                    type="text"
                    id="product"
                    aria-describedby="helper-text-explanation"
                    className="bg-white w-full box-shadow input-focus h-full px-[20px] py-[10px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none focus:ring-purple-600 focus:border-purple-600 block input"
                    placeholder="What's there first name?"
                  />
                  <input
                    onChange={handleChangeBgUpdate}
                    style={{ height: "44px" }}
                    value={nextOfKinLastName}
                    onInput={(e) => setnextOfKinLastName(e.target.value)}
                    type="text"
                    id="product"
                    aria-describedby="helper-text-explanation"
                    className="bg-white box-shadow input-focus w-full h-full px-[20px] py-[10px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none focus:ring-purple-600 focus:border-purple-600 block input"
                    placeholder="What's there last name?"
                  />
                </div>
              </div>
            </div>

            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="sex"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  Sex
                </label>
              </div>
              <div className="relative w-[512px]">
                <div className="relative" ref={kinSexDropDownRef}>
                  <button
                    type="button"
                    className="bg-white text-left w-[512px] h-[44px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus px-[14px] py-[10px]"
                    onClick={() => setIsSexKinDropDown(!isSexKinDropDown)}
                  >
                    <span className="flex items-center justify-between">
                      {kinGender || "Please select sex"}{" "}
                      {/* Display selected country or placeholder */}
                      <svg
                        className={`  ${
                          isCountryDropdownOpen ? "transform rotate-180" : ""
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M5 7.5L10 12.5L15 7.5"
                          stroke="#667085"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </button>

                  {isSexKinDropDown && (
                    <div className="z-20 w-[512px] absolute bg-white rounded-lg shadow mt-0">
                      <div className="z-20 absolute w-[512px] bg-white divide-y divide-gray-100 rounded-lg shadow block">
                        <ul className="py-1 text-sm text-gray-700 box-shadow dark:text-black-200">
                          <li>
                            <div
                              className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                setKinGender("Male");
                                setIsSexKinDropDown(!isSexKinDropDown);
                                handleChangeBgUpdate();
                              }}
                            >
                              Male
                            </div>
                          </li>
                          <li>
                            <div
                              className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                setKinGender("Female");
                                setIsSexKinDropDown(!isSexKinDropDown);
                                handleChangeBgUpdate();
                              }}
                            >
                              Female
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  htmlFor="date_of_birth"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  Date of Birth
                </label>
              </div>
              <div className="relative flex-grow">
                <input
                  onChange={handleChangeBgUpdate}
                  type="text"
                  style={{ height: "44px" }}
                  value={nextOfKinDateOfBirth}
                  onInput={(e) => setnextOfKinDateOfBirth(e.target.value)}
                  placeholder="DD/MM/YYYY"
                  id="input-group-1"
                  className="bg-white box-shadow input-focus w-[512px] h-full px-[12px] py-[10px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none focus:ring-purple-600 focus:border-purple-600 block"
                  ref={dobRef_b}
                />
              </div>
            </div>

            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="relation"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  Relationship
                </label>
              </div>
              <div className="relative w-[512px]">
                <div className="relative" ref={kinRelationRef}>
                  <button
                    type="button"
                    className="bg-white text-left w-[512px] h-[44px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus px-[14px] py-[10px]"
                    onClick={() =>
                      setIsRelationDropDownOpen(!isRelationDropDownOpen)
                    }
                  >
                    <span className="flex items-center justify-between">
                      {nextOfKinRelation || "Please select a relation"}{" "}
                      {/* Display selected country or placeholder */}
                      <svg
                        className={`  ${
                          isRelationDropDownOpen ? "transform rotate-180" : ""
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M5 7.5L10 12.5L15 7.5"
                          stroke="#667085"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </button>

                  {isRelationDropDownOpen && (
                    <div className="z-20 w-[512px] absolute bg-white rounded-lg shadow mt-0">
                      <div className="z-20 absolute w-[512px] bg-white divide-y divide-gray-100 rounded-lg shadow block">
                        <ul className="py-1 text-sm text-gray-700 box-shadow dark:text-black-200">
                          <li>
                            <div
                              className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                setnextOfKinRelation("Wife");
                                setIsRelationDropDownOpen(
                                  !isRelationDropDownOpen
                                );
                                handleChangeBgUpdate();
                              }}
                            >
                              Wife
                            </div>
                          </li>
                          <li>
                            <div
                              className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                setnextOfKinRelation("Husband");
                                setIsRelationDropDownOpen(
                                  !isRelationDropDownOpen
                                );
                                handleChangeBgUpdate();
                              }}
                            >
                              Husband
                            </div>
                          </li>
                          <li>
                            <div
                              className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                setnextOfKinRelation("Brother");
                                setIsRelationDropDownOpen(
                                  !isRelationDropDownOpen
                                );
                                handleChangeBgUpdate();
                              }}
                            >
                              Brother
                            </div>
                          </li>
                          <li>
                            <div
                              className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                setnextOfKinRelation("Sister");
                                setIsRelationDropDownOpen(
                                  !isRelationDropDownOpen
                                );
                                handleChangeBgUpdate();
                              }}
                            >
                              Sister
                            </div>
                          </li>

                          <li>
                            <div
                              className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                setnextOfKinRelation("Son");
                                setIsRelationDropDownOpen(
                                  !isRelationDropDownOpen
                                );
                                handleChangeBgUpdate();
                              }}
                            >
                              Son
                            </div>
                          </li>
                          <li>
                            <div
                              className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                setnextOfKinRelation("Daughter");
                                setIsRelationDropDownOpen(!isSexKinDropDown);
                                handleChangeBgUpdate();
                              }}
                            >
                              Daughter
                            </div>
                          </li>
                          <li>
                            <div
                              className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                setnextOfKinRelation("Mother");
                                setIsRelationDropDownOpen(
                                  !isRelationDropDownOpen
                                );
                                handleChangeBgUpdate();
                              }}
                            >
                              Mother
                            </div>
                          </li>
                          <li>
                            <div
                              className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                setnextOfKinRelation("Father");
                                setIsRelationDropDownOpen(
                                  !isRelationDropDownOpen
                                );
                                handleChangeBgUpdate();
                              }}
                            >
                              Father
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="id_document"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  ID Document Type
                </label>
              </div>
              <div className="relative w-[512px]">
                <div className="relative" ref={kinIdDocumentTypeDropDownRef}>
                  <button
                    type="button"
                    className="bg-white text-left w-[512px] h-[44px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus px-[14px] py-[10px]"
                    onClick={() =>
                      setisKinIdDocumentTypeOpen(!isKinIdDocumentTypeOpen)
                    }
                  >
                    <span className="flex items-center justify-between">
                      {kinDocumentType || "ID Document Type"}{" "}
                      {/* Display selected country or placeholder */}
                      <svg
                        className={`  ${
                          isKinIdDocumentTypeOpen ? "transform rotate-180" : ""
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M5 7.5L10 12.5L15 7.5"
                          stroke="#667085"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </button>

                  {isKinIdDocumentTypeOpen && (
                    <div className="z-20 w-[512px] absolute bg-white rounded-lg shadow mt-0">
                      <div className="z-20 absolute w-[512px] bg-white divide-y divide-gray-100 rounded-lg shadow block">
                        <ul className="py-1 text-sm text-gray-700 box-shadow dark:text-black-200">
                          <li>
                            <div
                              className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                setkinDocumentType("Passport");
                                setisKinIdDocumentTypeOpen(
                                  !isKinIdDocumentTypeOpen
                                );
                                handleChangeBgUpdate();
                              }}
                            >
                              Passport
                            </div>
                          </li>
                          <li>
                            <div
                              className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                setkinDocumentType("National ID");
                                setisKinIdDocumentTypeOpen(
                                  !isKinIdDocumentTypeOpen
                                );
                                handleChangeBgUpdate();
                              }}
                            >
                              National ID
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="id"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  ID #
                </label>
              </div>
              <div className="relative w-[512px]">
                <input
                  onChange={handleChangeBgUpdate}
                  style={{ height: "44px" }}
                  value={
                    nextOfKinId === "null"
                      ? ""
                      : nextOfKinId === ""
                      ? ""
                      : nextOfKinId
                  }
                  onInput={(e) => setnextOfKinId(e.target.value)}
                  type="text"
                  id="input-group-1"
                  className="bg-white box-shadow input-focus w-full h-full px-[20px] py-[10px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none focus:ring-purple-600 focus:border-purple-600 block input"
                  placeholder="ID #"
                />
              </div>
            </div>
            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="text"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  Physical Address 1
                </label>
              </div>
              <div className="w-[512px] space-y-5">
                <input
                  onChange={handleChangeBgUpdate}
                  type="text"
                  style={{ height: "44px" }}
                  value={nextOfKinPhysicalAddress}
                  onInput={(e) => setnexOfKinPhysicalAddress(e.target.value)}
                  id="product"
                  aria-describedby="helper-text-explanation"
                  className="bg-white box-shadow input-focus w-full h-full px-[20px] py-[10px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none focus:ring-purple-600 focus:border-purple-600 block input"
                  placeholder="Please enter next of kins street address 1"
                />
              </div>
            </div>
            {/* <div className="flex gap-[32px] my-7">
            <div className="w-[280px]">
              <label
                for="text"
                className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
              >
                Physical Address 2
              </label>
            </div>
            <div className="w-[512px] space-y-5">
              <input
                onChange={handleChangeBgUpdate}
                type="text"
                style={{ height: "44px" }}
                value={nextOfKinPhysicalAddress2}
                onInput={(e) => setnexOfKinPhysicalAddress2(e.target.value)}
                id="product"
                aria-describedby="helper-text-explanation"
                className="bg-white box-shadow input-focus w-full h-full px-[20px] py-[10px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none focus:ring-purple-600 focus:border-purple-600 block input"
                placeholder="Please enter next of kins street address 2"
              />
            </div>
          </div>
          <div className="flex gap-[32px] my-7">
            <div className="w-[280px]">
              <label
                for="text"
                className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
              >
                WhatsApp Number
              </label>
            </div>
            <div className="w-[512px] space-y-5">
              <input
                onChange={handleChangeBgUpdate}
                type="text"
                style={{ height: "44px" }}
                value={nextOfKinWhatsapp}
                onInput={(e) => setnexOfKinWhatsapp(e.target.value)}
                id="product"
                aria-describedby="helper-text-explanation"
                className="bg-white box-shadow input-focus w-full h-full px-[20px] py-[10px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none focus:ring-purple-600 focus:border-purple-600 block input"
                placeholder="Please enter next of kins whatsApp number"
              />
            </div>
          </div> */}

            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="email"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  City
                </label>
              </div>
              <div className="w-[512px] space-y-5">
                <div className="relative inline-block  h-[44px]">
                  <div className="w-[512px]" ref={cityKinDropdownRef}>
                    <button
                      type="button"
                      className="bg-white text-left w-full h-[44px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus px-[14px] py-[10px]"
                      onClick={() =>
                        setIsCityDropdownOpenKin(!isCityDropdownOpenKin)
                      }
                    >
                      <span className="flex items-center justify-between">
                        {nextOfKinCity || "Please select next of kins city "}
                        <svg
                          className={`  ${
                            isCityDropdownOpenKin ? "transform rotate-180" : ""
                          }`}
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M5 7.5L10 12.5L15 7.5"
                            stroke="#667085"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </button>

                    {isCityDropdownOpenKin && (
                      <div className="z-20 w-[512px] absolute bg-white rounded-lg shadow mt-0">
                        <ul className="py-1 text-sm box-shadow text-gray-700 dark:text-black-200  max-h-[300px] overflow-y-auto w-[512px] mt-0">
                          {cities.map((option, index) => (
                            <li key={index}>
                              <div
                                className="block px-4 py-2 cursor-pointer hover:bg-gray-100"
                                onClick={() => handleCityOptionClickKin(option)}
                              >
                                {option}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-[32px] my-7" ref={countryKinDropdownRef}>
              <div className="w-[280px]">
                <label
                  htmlFor="country"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  Country
                </label>
              </div>
              <div className="w-[512px] space-y-5">
                <div className="relative inline-block h-[44px]">
                  <div className="w-[512px]" ref={countryKinDropdownRef}>
                    <button
                      type="button"
                      className="bg-white text-left w-full h-[44px] rounded-[4px] border border-[#D0D5DD] text-[#101828] text-[16px] appearance-none input-focus px-[14px] py-[10px]"
                      onClick={() =>
                        setIsCountryDropdownOpenKin(!isCountryDropdownOpenKin)
                      }
                    >
                      <span className="flex items-center justify-between">
                        {nextOfKinCountry || "Select a country"}
                        <svg
                          className={`transition-transform ${
                            isCountryDropdownOpenKin
                              ? "transform rotate-180"
                              : ""
                          }`}
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M5 7.5L10 12.5L15 7.5"
                            stroke="#667085"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </button>

                    {isCountryDropdownOpenKin && (
                      <div className="absolute z-30 w-[512px] bg-white rounded-lg shadow-lg mt-0">
                        <ul className="py-1 text-sm text-gray-700 max-h-[300px] overflow-y-auto">
                          {countries.map((country) => (
                            <li
                              key={country.cca3}
                              className="flex items-center space-x-2 px-3 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() =>
                                handleCountryKinOptionClick(country.name.common)
                              }
                            >
                              <Flag
                                code={country.cca3}
                                style={{ width: "32px", height: "32px" }}
                              />
                              <span>{country.name.common}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="text-dark mb-[24px] mt-[24px] flex">
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.625 18.75C32.625 13.9875 28.7625 10.125 24 10.125C19.2375 10.125 15.375 13.9875 15.375 18.75C15.375 23.5125 19.2375 27.375 24 27.375C28.7625 27.375 32.625 23.5125 32.625 18.75ZM17.625 18.75C17.625 15.225 20.475 12.375 24 12.375C27.525 12.375 30.375 15.225 30.375 18.75C30.375 22.275 27.525 25.125 24 25.125C20.475 25.125 17.625 22.275 17.625 18.75Z"
                  fill="black"
                />
                <path
                  d="M9.56239 40.575C9.74989 40.6875 9.93739 40.725 10.1249 40.725C10.4999 40.725 10.9124 40.5375 11.0999 40.1625C13.7249 35.5125 18.6749 32.625 23.9999 32.625C29.3249 32.625 34.2749 35.5125 36.9374 40.1625C37.2374 40.6875 37.9499 40.875 38.4749 40.575C38.9999 40.275 39.1874 39.5625 38.8874 39.0375C35.8499 33.7125 30.1499 30.375 23.9999 30.375C17.8499 30.375 12.1499 33.7125 9.11239 39.0375C8.81239 39.5625 8.99989 40.275 9.56239 40.575Z"
                  fill="black"
                />
              </svg>

              <div className="ml-2">
                <h2 className="text-[18px] font-[500] leading-[28px] text-[#101828] flex items-center">
                  Customers Signature
                </h2>
                <p className="my-0.5 text-[#475467] text-[14px] leading-[20px]">
                  Customer details
                </p>
              </div>
            </div>
            <hr />

            <div className="flex gap-[32px] my-7">
              <div className="w-[280px]">
                <label
                  for="image"
                  className="block mb-2 text-[14px] leading-[20px] font-[500] text-[#344054] w-fit"
                >
                  {" "}
                  Customer Signature
                </label>
              </div>
              <FileUploader
                selectedImage={selectedImages.signature}
                showUploadImage={showUploadImage}
                showToast={showToast}
                typeStr="signature"
                handleFileChange={handleFileChange}
                handleDeleteFile={handleDeleteFile}
                handleShowSelection={handleShowSelection}
              />
            </div>
          </form>
        </div>
        <hr />
        <div className="container flex justify-end my-5 space-x-5">
          <button className="bg-gray-20 hover:bg-gray-100 text-dark-900 border border-black-100 py-2 px-5 rounded-lg">
            Cancel
          </button>

          <button
            onClick={(e) => {
              hanleUpdateUserProfile(e);
              setshowUploadImage((prev) => ({
                ...prev,
                signature: false,
                document: false,
                profile: false,
              }));
            }}
            className={
              isUpdated
                ? `
            bg-[#0439D7] hover:bg-blue-700 text-white border border-black-100 py-2 px-5 rounded-lg`
                : `bg-white text-[#0439D7] hover:bg-[#0439D7] hover:text-white  border border-[#0439D7] py-2 px-5 rounded-lg`
            }
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateCustomerForm;
